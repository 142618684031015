import { Checkbox, FormLabel, Radio, Textarea, Typography } from '@mui/joy';
import { Field, Formik, useFormik } from 'formik';
import styled from 'styled-components';
import { Input } from '@mui/joy';
import * as yup from 'yup';
import React, { useState } from 'react';
import Button from '@mui/joy/Button';
import axios from 'axios';
import { useError } from '../../components/ErrorProvider';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../components/NotificationProvider';

const UserInfoForm = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 500px;
`;

export const SingleLineTextInput = styled.input`
  padding: 10px;
  max-width: 350px;
  margin-bottom: 10px;
`;

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  email: yup.string().required('Email is required').email(),
  products: yup.string().required('Products are required'),
  tools: yup.string().required('Tools are required'),
});

export const SubmitUserInfo = () => {
  const { showError } = useError();
  const { showNotification } = useNotification();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      products: '',
      tools: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const response = await axios.post('/api/users/info', values);
        showNotification('10 free optimization credits added!');
        navigate('/optimizer');
      } catch (error) {
        showError('An unknown error occurred');
        navigate('/optimizer');
      }
    },
  });

  return (
    <>
      <Typography level={'h3'}>Submit User Info</Typography>
      <Typography level={'body-lg'}>
        Tell us a little bit about yourself to receive free optimization credits.
      </Typography>
      <UserInfoForm onSubmit={formik.handleSubmit}>
        <FormLabel sx={{ marginTop: '5px' }}>Name</FormLabel>
        <Input
          id="name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.name && Boolean(formik.errors.name)}
          placeholder="Name"
          // helperText={formik.touched.password && formik.errors.password}
        />
        <FormLabel sx={{ marginTop: '10px' }}>Email</FormLabel>
        <Input
          id="email"
          name="email"
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          placeholder="Email"
          // helperText={formik.touched.password && formik.errors.password}
        />
        <FormLabel sx={{ marginTop: '10px' }}>
          What products do you currently sell on Amazon?
        </FormLabel>
        <Textarea
          placeholder="You can list ASINs or direct links to products"
          id="products"
          name="products"
          value={formik.values.products}
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          error={formik.touched.products && Boolean(formik.errors.products)}
          minRows={2}
        />
        <FormLabel sx={{ marginTop: '10px' }}>
          What tools (if any) do you currently use to write listing content?
        </FormLabel>
        <Textarea
          placeholder="Any tools you use..."
          minRows={2}
          id="tools"
          name="tools"
          onBlur={formik.handleBlur}
          value={formik.values.tools}
          error={formik.touched.tools && Boolean(formik.errors.tools)}
          onChange={formik.handleChange}
        />
        <Button
          type="submit"
          sx={{
            marginTop: '10px;',
          }}
          disabled={!formik.isValid || formik.isSubmitting}
        >
          Claim free optimizations
        </Button>
      </UserInfoForm>
    </>
  );
};
