import { Typography } from '@mui/joy';
import styled from 'styled-components';
import { PageBlockTitle, PageBlockWrapper } from '../../components/Common';
import { Textarea } from '@mui/joy';
import Button from '@mui/joy/Button';
import { useState } from 'react';
import axios from 'axios';

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const TextAreaWrapper = styled.div`
  width: 50%;
  padding: 10px;
  box-sizing: border-box;
`;

const Distance = styled.div`
  margin-top: 20px;
`;

export const InternalTextDistancePage = () => {
  const [textA, setTextA] = useState('');
  const [textB, setTextB] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [distance, setDistance] = useState<number | undefined>(undefined);

  const calculateDistance = async () => {
    setIsLoading(true);
    const resp = await axios.post('/api/search-phrases/textDistance', {
      textSample1: textA,
      textSample2: textB,
    });
    setDistance(resp.data.similarity);
    setIsLoading(false);
  };

  return (
    <PageBlockWrapper>
      <PageBlockTitle>Text Distance Tool</PageBlockTitle>
      <InputWrapper>
        <TextAreaWrapper>
          <Typography>Text Sample A</Typography>
          <Textarea minRows={3} value={textA} onChange={(e) => setTextA(e.target.value)} />
        </TextAreaWrapper>
        <TextAreaWrapper>
          <Typography>Text Sample B</Typography>
          <Textarea minRows={3} value={textB} onChange={(e) => setTextB(e.target.value)} />
        </TextAreaWrapper>
      </InputWrapper>
      <Button
        color="primary"
        fullWidth={true}
        sx={{
          marginTop: '20px',
        }}
        onClick={calculateDistance}
      >
        {isLoading ? 'Loading...' : 'Calculate Distance'}
      </Button>
      {distance !== undefined && (
        <Distance>
          <Typography level={'h4'}>Similarity: {distance}</Typography>
          <Typography>
            0.95 - 1.00: Close to Identical In this range, the text embeddings are nearly identical,
            indicating that the strings of text are extremely similar in meaning, context, or style.
          </Typography>

          <Typography>
            0.90 - 0.95: Very Similar Text embeddings within this range are very similar, suggesting
            a strong relation in terms of meaning, thematic content, or stylistic elements.
          </Typography>

          <Typography>
            0.75 - 0.90: Moderately Similar This range indicates a moderate level of similarity. The
            strings of text share some common characteristics but also possess notable differences,
            whether in meaning, context, or style.
          </Typography>

          <Typography>
            0.50 - 0.75: Somewhat Similar Text embeddings in this range are somewhat similar. They
            may be related in a broader sense, sharing some contextual or thematic similarities, but
            also differ in various aspects.
          </Typography>

          <Typography>
            0.25 - 0.50: Slightly Similar Here, the similarity is marginal. The strings of text
            might share very few attributes or be related in a very loose or indirect way, such as
            having a common general topic but different specific contents or viewpoints.
          </Typography>

          <Typography>
            0 - 0.25: Distant or Unrelated Text embeddings falling into this range are distant or
            completely unrelated. They likely convey very different meanings, discuss entirely
            different topics, or use completely different stylistic approaches.
          </Typography>
        </Distance>
      )}
    </PageBlockWrapper>
  );
};
