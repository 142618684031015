import { useParams } from 'react-router-dom';
import { Chip, Link, Tab, TabList, TabPanel, Tabs, Typography } from '@mui/joy';
import {
  PostProcessingIterationRun,
  PromptIterationRun,
  PromptIterationRunRow,
} from '../../monorepoShared/types';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNotification } from '../../components/NotificationProvider';
import { LongLoader } from '../../components/LongLoader';
import styled from 'styled-components';
import Button from '@mui/joy/Button';
import { PromptIterationSingleResult } from './PromptIterationSingleResult';

const LongLoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  width: 100%;
`;

const Flex = styled.div`
  display: flex;
`;

const HyperParameterWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

export const SinglePostProcessingIteration = ({}: {}) => {
  const [iterationRun, setIterationRun] = useState<PostProcessingIterationRun | undefined>(
    undefined,
  );
  const [isLoading, setLoading] = useState<boolean>(false);
  const { iterationId } = useParams();
  const { showNotification } = useNotification();

  const fetchIterationRun = async (
    iterationId: string,
  ): Promise<PostProcessingIterationRun | undefined> => {
    try {
      const resp = await axios.get<PostProcessingIterationRun>(
        `/api/fine-tune/singlePostProcessingRun/${iterationId}`,
      );
      return resp.data;
    } catch (err) {
      console.error(err);
      showNotification('Error fetching iteration run');
    }
  };

  useEffect(() => {
    if (!iterationId) {
      return;
    }
    setLoading(true);
    fetchIterationRun(iterationId).then((resp) => {
      setLoading(false);
      setIterationRun(resp);
    });
  }, [iterationId]);

  if (isLoading) {
    return (
      <LongLoadingWrapper>
        <LongLoader loadingMessages={['Loading iteration run']} />
      </LongLoadingWrapper>
    );
  }

  if (!iterationRun) {
    return <Typography>Iteration not found</Typography>;
  }

  // @ts-ignore
  return (
    <>
      <Flex>
        <Button
          sx={{
            marginRight: '10px',
          }}
          variant={'outlined'}
          onClick={() => {
            window.history.back();
          }}
        >
          Back To Input Page
        </Button>
        <Typography level={'h2'} fontWeight={600}>
          Post Processing Iteration Run Result: {iterationRun.id}
        </Typography>
      </Flex>

      <Typography
        level={'body-md'}
        sx={{
          marginBottom: '10px',
        }}
      >
        {iterationRun.notes}
      </Typography>

      {/*<HyperParameterWrapper>*/}
      {/*  <Chip sx={{ marginRight: '10px' }} startDecorator={'🌡️'}>*/}
      {/*    Temperature: {iterationRun.temperature}*/}
      {/*  </Chip>*/}
      {/*  <Chip sx={{ marginRight: '10px' }} startDecorator={'🅿️'}>*/}
      {/*    TopP: {iterationRun.topP}*/}
      {/*  </Chip>*/}
      {/*  <Chip sx={{ marginRight: '10px' }} startDecorator={'🙅'}>*/}
      {/*    Frequency Penalty: {iterationRun.frequencyPenalty}*/}
      {/*  </Chip>*/}
      {/*  <Chip sx={{ marginRight: '10px' }} startDecorator={'🙅'}>*/}
      {/*    Presence Penalty: {iterationRun.presencePenalty}*/}
      {/*  </Chip>*/}
      {/*</HyperParameterWrapper>*/}

      <Typography level={'body-md'} fontWeight={600} sx={{ marginBottom: '10px' }}>
        Objective Quality Metrics
      </Typography>
      <HyperParameterWrapper>
        <Chip sx={{ marginRight: '10px' }} startDecorator={''}>
          Title Length: {JSON.stringify(iterationRun.objectiveResultsAggregation.titleLength)}
        </Chip>
        <Chip sx={{ marginRight: '10px' }} startDecorator={''}>
          Description Length:{' '}
          {JSON.stringify(iterationRun.objectiveResultsAggregation.descriptionLength)}
        </Chip>
        <Chip sx={{ marginRight: '10px' }} startDecorator={''}>
          Bullet Length:{' '}
          {JSON.stringify(iterationRun.objectiveResultsAggregation.bulletPointsLength)}
        </Chip>
      </HyperParameterWrapper>

      <Tabs size={'sm'}>
        <TabList sx={{ flexWrap: 'wrap' }}>
          {iterationRun?.results?.rows.map((row, i) => {
            return <Tab tabIndex={i}>{row.asin}</Tab>;
          })}
        </TabList>
        {iterationRun?.results?.rows.map((row, i) => {
          return (
            <TabPanel value={i} tabIndex={i}>
              <PromptIterationSingleResult {...row} />
            </TabPanel>
          );
        })}
      </Tabs>
    </>
  );
};
