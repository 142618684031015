import styled from 'styled-components';
import { HEADER_HEIGHT } from '../../styleConsts';

export const FakeHeader = styled.div`
  height: 80px;
  width: 100%;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
`;

export const LoginPageWrapper = styled.div`
  background-color: white;
  height: 100vh;
`;

export const LoginForm = styled.form`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const LoginInput = styled.input<{ error: string }>`
  border: 1px solid ${(props) => (props.error ? 'red' : '#e0e0e0')};
  padding: 20px 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  font-size: 14px;
`;

export const LoginButton = styled.button`
  height: 50px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  background-color: black;

  &:disabled {
    background-color: #e0e0e0;
    color: #9b9b9b;
    cursor: not-allowed;
  }
`;

export const FakeTitle = styled.div`
  img {
    height: 29px;
    margin: 18px;
    cursor: pointer;
    margin-top: 25px;
  }
`;

export const FieldError = styled.span`
  color: red;
  font-size: 12px;
  //margin-top: -15px;
`;
