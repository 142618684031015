import React, { useEffect, useState } from 'react';
import { Skeleton, Textarea, Typography } from '@mui/joy';
import styled from 'styled-components';
import Button from '@mui/joy/Button';
import {
  Check,
  ContentCopyOutlined,
  SmartToyOutlined,
  ThumbDownAltOutlined,
  ThumbUpAltOutlined,
} from '@mui/icons-material';
import { renderToString } from 'react-dom/server';
import Box from '@mui/material/Box';
import axios from 'axios';
import {
  RegenerateFieldName,
  RegenerateFieldRequest,
  SingleChannelOptimizedListing,
} from '../../monorepoShared/types';

const LoadingTypography = styled(Typography)`
  background: linear-gradient(90deg, #eee, #ddd, #eee);
  background-size: 200% 100%;
  animation: loading 1.5s linear infinite;
  color: transparent;

  @keyframes loading {
    0% {
      background-position: 0 0;
    }
    99% {
      background-position: 200% 0;
    }
    100% {
      background-position: 0 0;
    }
  }
`;

const TextHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const TextFooter = styled.div`
  position: absolute;
  bottom: 3px;
  right: 10px;
`;

const FakeTextarea = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 5px;
  padding: 15px;
  box-sizing: border-box;
`;

const OptimizedListingDisplayItemWrapper = styled.div`
  margin-bottom: 20px;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 5px;
`;

export const OptimizedListingDisplay = ({
  listing,
  toHighlight,
  shouldHighlight,
  loading,
}: {
  listing: SingleChannelOptimizedListing;
  toHighlight: Record<string, string>;
  shouldHighlight: boolean;
  loading: boolean;
}) => {
  const [singleChannelOptimizedListing, setSingleChannelOptimizedListing] =
    useState<SingleChannelOptimizedListing>(listing);
  const [forceShowRegenerating, setForceShowRegenerating] = useState<
    RegenerateFieldName | undefined
  >();

  const regenerate = async (field: RegenerateFieldName, feedback: string) => {
    setForceShowRegenerating(field);
    const req: RegenerateFieldRequest = {
      singleChannelId: listing.id,
      fieldName: field,
      feedback: feedback,
    };
    const singleChannelOptimizedListing = await axios.post<SingleChannelOptimizedListing>(
      '/api/optimize/regenerateField',
      req,
    );

    setSingleChannelOptimizedListing(singleChannelOptimizedListing.data);

    while (true) {
      const response = await axios.get<SingleChannelOptimizedListing>(
        `/api/optimize/single-channel/${listing.id}`,
      );
      if (response.data.optimizationStatus === 'COMPLETED') {
        setSingleChannelOptimizedListing(response.data);
        setForceShowRegenerating(undefined);
        break;
      }
      await new Promise((r) => setTimeout(r, 1000));
    }
  };

  useEffect(() => {
    setSingleChannelOptimizedListing(listing);
  }, [listing]);

  return (
    <>
      <OptimizedListingDisplayItem
        field={RegenerateFieldName.TITLE}
        text={singleChannelOptimizedListing.title!}
        title={'New Optimized Product Title'}
        keywordsToHiglight={toHighlight}
        maxCharCount={200}
        shouldHighlight={shouldHighlight}
        includeAcceptAndFeedbackButtons={true}
        regenerate={regenerate}
        loading={loading}
      />
      <OptimizedListingDisplayItem
        field={RegenerateFieldName.DESCRIPTION}
        text={singleChannelOptimizedListing.description!}
        title={'New Optimized Description'}
        keywordsToHiglight={toHighlight}
        maxCharCount={2000}
        shouldHighlight={shouldHighlight}
        includeAcceptAndFeedbackButtons={true}
        regenerate={regenerate}
        loading={loading}
      />

      {singleChannelOptimizedListing.bulletPoints!.map((bulletPoint, index) => {
        if (index === 0) {
          return (
            <OptimizedListingDisplayItem
              field={RegenerateFieldName.BULLET_POINTS}
              text={bulletPoint}
              title={'New Optimized Bullet Points'}
              keywordsToHiglight={toHighlight}
              maxCharCount={2000}
              shouldHighlight={shouldHighlight}
              includeAcceptAndFeedbackButtons={true}
              regenerate={regenerate}
              key={index}
              loading={loading}
            />
          );
        } else {
          return (
            <OptimizedListingDisplayItem
              field={RegenerateFieldName.BULLET_POINTS}
              text={bulletPoint}
              title={''}
              keywordsToHiglight={toHighlight}
              maxCharCount={2000}
              shouldHighlight={shouldHighlight}
              includeAcceptAndFeedbackButtons={false}
              regenerate={regenerate}
              key={index}
              loading={loading}
            />
          );
        }
      })}
    </>
  );
};

const MaybeHiglightedText = ({
  text,
  toHighlight,
  shouldHighlight,
  loading,
}: {
  text: string;
  toHighlight: Record<string, string>;
  shouldHighlight: boolean;
  loading: boolean;
}) => {
  const toHighlightKeys = Object.keys(toHighlight);
  let inner = text;
  if (shouldHighlight) {
    toHighlightKeys.forEach((key) => {
      const regex = new RegExp('\\b' + key + '\\b', 'gi');

      inner = inner.replace(regex, function (matched) {
        const replace = (
          <span
            style={{
              backgroundColor: toHighlight[key],
              outline: `1px solid ${toHighlight[key]}`,
              padding: '2px 0px',
              borderRadius: '5px',
            }}
          >
            {matched}
          </span>
        );

        return `${renderToString(replace)}`;
      });
    });
  }

  return (
    <Typography level={'body-sm'}>
      {loading ? (
        <Skeleton variant={'inline'} loading={true}>
          {text}
        </Skeleton>
      ) : (
        <span
          dangerouslySetInnerHTML={{
            __html: inner,
          }}
        />
      )}
    </Typography>
  );
};

const OptimizedListingDisplayItem = ({
  field,
  title,
  text,
  keywordsToHiglight,
  maxCharCount,
  shouldHighlight,
  includeAcceptAndFeedbackButtons,
  regenerate,
  loading,
}: {
  // TODO -- make this something else
  field: RegenerateFieldName;
  title: string;
  text: string;
  keywordsToHiglight: Record<string, string>;
  maxCharCount: number;
  shouldHighlight: boolean;
  includeAcceptAndFeedbackButtons: boolean;
  regenerate: (fieldName: RegenerateFieldName, feedback: string) => void;
  loading: boolean;
}) => {
  const [showCopyCheck, setShowCopyCheck] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedback, setFeedback] = useState('');

  const regenerateInner = async () => {
    regenerate(field, feedback);
    setShowFeedback(false);
    setFeedback('');
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text).then(() => {
      setShowCopyCheck(true);
    });
    setTimeout(() => {
      setShowCopyCheck(false);
    }, 1000);
  };

  return (
    <OptimizedListingDisplayItemWrapper>
      <TextHeader>
        <Typography level="title-md" fontWeight={'500'}>
          {title}
        </Typography>
        <ButtonGroup>
          {includeAcceptAndFeedbackButtons && (
            <>
              {/*<Button*/}
              {/*  color="neutral"*/}
              {/*  variant="outlined"*/}
              {/*  style={{*/}
              {/*    backgroundColor: accepted ? '#d4edda' : 'white',*/}
              {/*  }}*/}
              {/*  size={'sm'}*/}
              {/*  startDecorator={<ThumbUpAltOutlined sx={{ fontSize: '14px' }} />}*/}
              {/*  onClick={() => {*/}
              {/*    setAccepted(!accepted);*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <Typography level="body-sm">Accept</Typography>*/}
              {/*</Button>*/}
              {/*<Button*/}
              {/*  color="neutral"*/}
              {/*  variant="outlined"*/}
              {/*  size={'sm'}*/}
              {/*  startDecorator={<SmartToyOutlined sx={{ fontSize: '14px' }} />}*/}
              {/*  onClick={() => {*/}
              {/*    setShowFeedback(!showFeedback);*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <Typography level="body-xs">Regenerate</Typography>*/}
              {/*</Button>*/}
            </>
          )}
          <Button
            color="neutral"
            variant="outlined"
            size={'sm'}
            sx={{
              height: '20px',
            }}
            startDecorator={
              showCopyCheck ? (
                <Check color={'success'} sx={{ width: '14px' }} />
              ) : (
                <ContentCopyOutlined sx={{ fontSize: '14px' }} />
              )
            }
            onClick={copyToClipboard}
          >
            <Typography level="body-xs">Copy</Typography>
          </Button>
        </ButtonGroup>
      </TextHeader>
      <FakeTextarea>
        <MaybeHiglightedText
          text={text}
          toHighlight={keywordsToHiglight}
          shouldHighlight={shouldHighlight}
          loading={loading}
        />
        <TextFooter>
          <Typography level={'body-xs'} marginLeft={'5px'}>
            <Skeleton variant={'inline'} loading={loading}>
              {text.split('').length}
            </Skeleton>{' '}
            / {maxCharCount}
          </Typography>
        </TextFooter>
      </FakeTextarea>
      {/*TODO BREAK THIS OUT INTO NEW COMPONENT*/}
      {showFeedback && (
        <Textarea
          value={feedback}
          onChange={(e) => {
            setFeedback(e.target.value);
          }}
          placeholder="Tell us what you'd like to see changed and we'll generate a new title. Ex: 'This description needs to feature more specific details about the product'"
          minRows={3}
          style={{
            marginTop: '5px',
            paddingTop: '15px',
          }}
          endDecorator={
            <Box
              sx={{
                display: 'flex',
                gap: 'var(--Textarea-paddingBlock)',
                pt: 'var(--Textarea-paddingBlock)',
                borderTop: '1px solid',
                borderColor: 'divider',
                flex: 'auto',
              }}
            >
              <Button variant="outlined" sx={{ ml: 'auto' }} onClick={regenerateInner}>
                Send Feedback & Generate New {title}
              </Button>
            </Box>
          }
        />
      )}
    </OptimizedListingDisplayItemWrapper>
  );
};
