import { Card, CardActions, CardContent, Chip, Typography } from '@mui/joy';
import { PageBlockWrapper } from '../../components/Common';
import Button from '@mui/joy/Button';
import styled from 'styled-components';
import { Check } from '@mui/icons-material';
import { useAuth } from '../../components/auth/authHooks';

type CreditPlan = {
  id: string;
  planName: string;
  numCredits: number;
  price: number | string;
  discountChip?: string;
  description: string;
  benefits: Array<string>;
  showPrice: boolean;
  buyURL?: string;
};

const plans: Array<CreditPlan> = [
  {
    id: '1',
    planName: '10 Credits',
    numCredits: 10,
    price: 10,
    description:
      'Enough credits to optimize a small catalog of products for a set list of search phrases',
    benefits: [
      '10 Listing Optimizations',
      'Never Expires',
      'Unlimited Keyword Searches',
      'Basic SEO Performance Data',
    ],
    showPrice: true,
    buyURL: 'https://buy.stripe.com/9AQ9C4dlrfnnf84eUW',
  },
  {
    id: '2',
    planName: '100 Credits',
    numCredits: 100,
    price: 80,
    discountChip: 'Save 20%',
    description:
      'Enough credits to optimize a larger catalog of products for multiple search phrases',
    benefits: [
      '100 Listing Optimizations',
      'Never Expires',
      'Unlimited Keyword Searches',
      'Basic SEO Performance Data',
    ],
    showPrice: true,
    buyURL: 'https://buy.stripe.com/28o4hK0yF4IJf845kn',
  },
  {
    id: '3',
    planName: 'Enterprise Plan',
    numCredits: 100,
    price: 'Contact Us',
    description: 'Dedicated support and custom tools for large catalogs and enterprise clients',
    benefits: [
      'Unlimited Optimizations',
      'Custom SEO Performance Data',
      'Sync Product Catalog',
      'Realtime Product Optimization',
      'Dedicated Account Manager',
      'Custom Feature Requests',
    ],
    showPrice: false,
  },
];

const PlanCard = ({ plan }: { plan: CreditPlan }) => {
  const { user } = useAuth();

  const userId = user?.id;
  const email = user?.email;
  const formattedPriceComponent = plan.showPrice ? (
    <Typography level={'h1'} marginBottom={'10px'}>
      ${plan.price}{' '}
      <Typography level={'body-sm'} fontWeight={400}>
        One-time purchase
      </Typography>
    </Typography>
  ) : (
    <Typography level={'h1'} marginBottom={'10px'}>
      {plan.price}
    </Typography>
  );

  return (
    <Card
      sx={{
        width: 280,
        margin: 2,
      }}
    >
      <CardContent>
        <Typography level={'body-md'} fontWeight={600} marginBottom={'5px'}>
          {plan.planName}
          {plan.discountChip && (
            <Chip
              size={'sm'}
              color={'success'}
              sx={{
                marginLeft: '5px',
                marginTop: '-3px',
              }}
            >
              {plan.discountChip}
            </Chip>
          )}
        </Typography>
        <Typography level={'body-sm'} marginBottom={'10px'}>
          {plan.description}
        </Typography>
        {formattedPriceComponent}
        {plan.benefits.map((benefit) => (
          <Typography key={benefit} level={'body-sm'} startDecorator={<Check />}>
            {benefit}
          </Typography>
        ))}
      </CardContent>
      <CardActions>
        <Button
          loading={userId === undefined}
          color={'success'}
          onClick={() => {
            // TODO -- if this is th enterprise plan, open a contact form
            window.open(
              `${plan.buyURL}?client_reference_id=${userId}&prefilled_email=${email}`,
              '_blank',
            );
          }}
        >
          {plan.showPrice ? 'Purchase Credits' : 'Contact Us'}
        </Button>
      </CardActions>
    </Card>
  );
};

const PlanWrapper = styled.div`
  display: flex;
`;

export const PurchaseCredits = () => {
  return (
    <PageBlockWrapper>
      <Typography level={'h2'}>Purchase More Credits</Typography>
      <Typography>
        Credits can be used to create optimizations for products based on search phrases. Each
        credit allows a single optimization. Credits never expire and can be used at any time.
      </Typography>

      <PlanWrapper>
        {plans.map((plan) => (
          <PlanCard key={plan.id} plan={plan} />
        ))}
      </PlanWrapper>
    </PageBlockWrapper>
  );
};
