import React from 'react';
import { AuthContext } from './AuthProvider';
import { GetUsageResponse } from '../../monorepoShared/types';

export const useAuth = (): {
  user: any;
  isAuthenticated: boolean;
  register: (registerData: { email: string; password: string }) => Promise<void>;
  login: (loginData: { email: string; password: string }) => Promise<void>;
  logout: () => void;
  axiosConfigured: boolean;
  getUsage: () => Promise<GetUsageResponse>;
} => {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('AuthContext must be within AuthProvider');
  }

  // @ts-ignore
  return context;
};
