import { PageBlockWrapper } from '../../components/Common';
import styled from 'styled-components';
import { OptimizedListingDisplay } from './OptimizedListingDisplay';
import { OptimizationInfoBlock } from './OptimizationInfoBlock';
import React, { useState } from 'react';
import { ReferenceProductListing, SingleChannelOptimizedListing } from '../../monorepoShared/types';
import { Typography } from '@mui/joy';
import {
  mockOptimizationForLoading,
  mockReferenceProduct,
} from '../ListingOptimizerV2/mockReferenceProduct';
import { LongLoader } from '../../components/LongLoader';

const TwoColumnWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const MainColumn = styled.div`
  min-width: 66%;
`;

const SideColumn = styled.div`
  flex-grow: 1;
  padding-left: 20px;
`;

const lightPastelColors = [
  '#f8d7da',
  '#d4edda',
  '#cce5ff',
  '#d1ecf1',
  '#fff3cd',
  '#d6d8d9',
  '#f5c6cb',
  '#c3e6cb',
];

const Spacer = styled.div`
  height: 100px;
`;

export const SingleOptimizedListingLayout = ({
  optimizedListing,
  selectedSearchPhrases,
}: {
  optimizedListing: SingleChannelOptimizedListing;
  selectedSearchPhrases: Array<string>;
}) => {
  const [shouldHighlight, setShouldHighlight] = useState(true);

  if (optimizedListing.optimizationStatus === 'FAILED') {
    return (
      <PageBlockWrapper>
        <Typography level="h4">
          Optimization failed. Please reload the product and try again.
        </Typography>
      </PageBlockWrapper>
    );
  }

  const loading = optimizedListing.optimizationStatus === 'REQUESTED';
  if (loading) {
    // return (
    //   <>
    //     <Spacer />
    //     <LongLoader
    //       loadingMessages={[
    //         'Scraping data from amazon',
    //         'Analyzing keyword performance',
    //         'Processing product attributes',
    //         'Creating initial content draft',
    //         'Calculating performance metrics',
    //         'Revising initial draft',
    //         'Calculating performance metrics',
    //         'Revising iterative draft',
    //         'Calculating performance metrics',
    //         'Revising iterative draft',
    //       ]}
    //     />
    //   </>
    // );
  }

  const keywordSet = new Set<string>();
  selectedSearchPhrases.forEach((searchPhrase) => {
    searchPhrase.split(' ').forEach((word) => {
      if (
        [
          'the',
          'a',
          'an',
          'and',
          'or',
          'but',
          'of',
          'at',
          'by',
          'from',
          'for',
          'with',
          'in',
          'to',
        ].includes(word)
      ) {
        return;
      }
      // remove punctuation
      word = word.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '');
      keywordSet.add(word);
    });
  });

  const toHighlight: Record<string, string> = {};
  let index = 0;
  Array.from(keywordSet).forEach((searchPhrase) => {
    toHighlight[searchPhrase] = lightPastelColors[index % lightPastelColors.length];
    index += 1;
  });

  return (
    <>
      <TwoColumnWrapper>
        <MainColumn>
          <OptimizedListingDisplay
            listing={loading ? mockOptimizationForLoading : optimizedListing}
            toHighlight={toHighlight}
            shouldHighlight={shouldHighlight}
            loading={loading}
          />
        </MainColumn>
        <SideColumn>
          <OptimizationInfoBlock
            prioritizedKeywords={Array.from(keywordSet)}
            toHighlight={toHighlight}
            rationale={
              loading ? mockOptimizationForLoading.rationale! : optimizedListing.rationale!
            }
            shouldHighlight={shouldHighlight}
            setShouldHighlight={setShouldHighlight}
            referenceProductListings={
              loading
                ? mockOptimizationForLoading.referenceProductListings
                : optimizedListing.referenceProductListings
            }
            loading={loading}
          />
        </SideColumn>
      </TwoColumnWrapper>
    </>
  );
};
