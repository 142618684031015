import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import styled from 'styled-components';
import React, { useEffect } from 'react';
import { KeywordResearchData } from './KeywordResearchData';
import { LongLoader } from '../../components/LongLoader';
import { useError } from '../../components/ErrorProvider';
import axios from 'axios';
import { useAuth } from '../../components/auth/authHooks';
import { useNavigate, useParams } from 'react-router-dom';
import { PageBlockTitle, PageBlockWrapper } from '../../components/Common';
import {
  GetOptimizationResponse,
  GetOptimizerPageCacheResponse,
  GetUsageResponse,
  randomASINs,
} from '../../monorepoShared/types';
import { Splash } from './Splash';

export const SearchBarWrapper = styled.div`
  display: flex;
`;

export const FlexGrow = styled.div`
  flex-grow: 1;
`;

const LoadingWrapper = styled.div`
  margin-top: 100px;
  margin-bottom: 100px;
`;

const ErrorTextWrapper = styled.div`
  margin-top: 10px;
  padding: 5px;
  span {
    color: red;
    font-weight: 500;
  }
`;

const Spacing = styled.div`
  height: 20px;
`;

// TODO(easy) move this to another file so that we can delete this class altogehter
export const getRandomAsin = () => {
  const ASINS = randomASINs;
  return ASINS[Math.floor(Math.random() * ASINS.length)];
};

export const getAsinOrSearchPhrase = (
  rawUserInput: string,
): {
  type: 'asin' | 'searchPhrase';
  value: string;
} => {
  // first try to get the asin out of the url
  const asin = getAsinFromUrl(rawUserInput);
  if (asin) {
    return { type: 'asin', value: asin };
  }

  // next, check if the input "looks" like an asin
  if (rawUserInput.length === 10 && rawUserInput.match(/^[A-Z0-9]+$/)) {
    return { type: 'asin', value: rawUserInput };
  }

  return {
    type: 'searchPhrase',
    value: rawUserInput,
  };
};

// TODO(easy) move this to another file so that we can delete this class altogehter
export const getAsinFromUrl = (url: string): string | undefined => {
  const regex = /\/dp\/([A-Z0-9]+)/;
  const match = url.match(regex);
  if (match) {
    return match[1];
  } else {
    return undefined;
  }
};

// TODO(!!) AHH this lives in two places!
type TimeSeriesData =
  | {
      date: string;
    }
  | { [key: string]: number };

// some sort of score based on?
type SearchPhraseData = {
  relevanceScore: number;
  searchFrequencyScore: number;
};

export type KeywordResearchResponse = {
  searchPhrases: Array<string>;
  searchPhraseRankTimeSeries: Array<TimeSeriesData>;
  searchPhraseData: Record<string, SearchPhraseData>;
};

export function ListingOptimizer() {
  return (
    <PageBlockWrapper>
      <h1> Delet emej</h1>
    </PageBlockWrapper>
  );
}
