import {
  Channel,
  OptimizationStatus,
  ReferenceProductListing,
  SingleChannelOptimizedListing,
} from '../../monorepoShared/types';

export const mockReferenceProduct: ReferenceProductListing = {
  title:
    'Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. ',
  description: 'fds',
  bulletPoints: ['Loading...', 'Loading...', 'Loading...'],
  productUrl: 'Loading...',
  productIdentifier: 'Loading...',
  productIdentifierType: 'Loading...',
};

// optimizedListingOrMock.title =
//   'Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. ';
// optimizedListingOrMock.description =
//   'Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. ';
// optimizedListingOrMock.bulletPoints = [
//   'Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus.  Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus.',
//   'Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac',
//   'Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac',
//   'Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus.  Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus.  Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus.',
//   'Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus.  Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus.  Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus.',
// ];
// optimizedListingOrMock.referenceProductListings = [
//   mockReferenceProduct,
//   mockReferenceProduct,
//   mockReferenceProduct,
// ];
// optimizedListingOrMock.searchPhrases = ['Crayon', 'Pencil', 'Pen', 'Marker', 'Paintbrush'];
// optimizedListing.rationale =
//   'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vitae accumsan tortor, in bibendum justo. Maecenas sed ligula et ipsum iaculis suscipit. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Vestibulum elementum hendrerit nisi, ac euismod velit porta at. Donec in arcu at neque viverra consequat sit amet nec augue. Donec pretium blandit tortor, ';
//
export const mockOptimizationForLoading: SingleChannelOptimizedListing = {
  id: 10,
  title:
    'Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. ',
  description:
    'Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. ',
  bulletPoints: [
    'Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus.  Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus.',
    'Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac',
    'Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac',
    'Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus.  Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus.  Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus.',
    'Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Lorem ipsum dolor sit amet. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus.  Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus.  Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus.',
  ],
  optimizationStatus: OptimizationStatus.REQUESTED,
  channel: Channel.AMAZON,
  rationale:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vitae accumsan tortor, in bibendum justo. Maecenas sed ligula et ipsum iaculis suscipit. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Vestibulum elementum hendrerit nisi, ac euismod velit porta at. Donec in arcu at neque viverra consequat sit amet nec augue. Donec pretium blandit tortor',
  searchPhrases: ['Crayon', 'Pencil', 'Paintbrush', 'Crayon', 'Pencil'],
  prompt: 'fjdk',
  referenceProductListings: [
    mockReferenceProduct,
    mockReferenceProduct,
    mockReferenceProduct,
    mockReferenceProduct,
  ],
};
