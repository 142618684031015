// TODO: look into recharts
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import React from 'react';
import { KeywordResearchResponse } from './ListingOptimizer';
import Button from '@mui/joy/Button';
import styled from 'styled-components';
import { Typography } from '@mui/joy';

const LineGraphHeightController = styled.div`
  height: 250px;
`;

const ChartControlSection = styled.div``;

const strokes: Array<string> = [
  '#8884d8',
  '#82ca9d',
  '#ffc658',
  '#ff7300',
  '#00ff00',
  '#ff0000',
  '#0000ff',
  '#00ffff',
  '#ff00ff',
  '#ffff00',
  '#808080',
  '#800000',
];

enum TimeFrame {
  RESET = 'Reset',
  LAST_30_DAYS = 'Last 30 Days',
  LAST_90_DAYS = 'Last 90 Days',
  LAST_WINTER = 'Last Winter',
  LAST_SPRING = 'Last Spring',
  LAST_SUMMER = 'Last Summer',
  LAST_FALL = 'Last Fall',
  THIS_MONTH_LAST_YEAR = 'This Month Last Year',
}

const allTimeFrames: Array<TimeFrame> = [
  TimeFrame.RESET,
  // TimeFrame.LAST_30_DAYS,
  // TimeFrame.LAST_90_DAYS,
  TimeFrame.LAST_WINTER,
  TimeFrame.LAST_SPRING,
  TimeFrame.LAST_SUMMER,
  TimeFrame.LAST_FALL,
  // TimeFrame.THIS_MONTH_LAST_YEAR,
];

// @ts-ignore
export const Chart = ({
  resp,
  selectedSearchPhrases,
}: {
  resp: KeywordResearchResponse;
  selectedSearchPhrases: Set<string>;
}) => {
  const [refAreaLeft, setRefAreaLeft] = React.useState<string | undefined>(undefined);
  const [refAreaRight, setRefAreaRight] = React.useState<string | undefined>(undefined);
  const [left, setLeft] = React.useState<string>('dataMin');
  const [right, setRight] = React.useState<string>('dataMax');
  const [dataSlice, setDataSlice] = React.useState<Array<any>>(resp.searchPhraseRankTimeSeries);

  const selectedSearchPhrasesArray = Array.from(selectedSearchPhrases);

  const allDataForSelectedPhases: Array<number> = [];
  resp.searchPhraseRankTimeSeries.forEach((o: any) => {
    for (let i = 0; i < selectedSearchPhrasesArray.length; i++) {
      allDataForSelectedPhases.push(o[selectedSearchPhrasesArray[i]]);
    }
  });

  const minY = Math.min(...allDataForSelectedPhases);

  const zoom = () => {
    setRefAreaRight(undefined);
    setRefAreaLeft(undefined);
    if (refAreaLeft === refAreaRight || refAreaRight === '' || !refAreaRight || !refAreaLeft) {
      return;
    }

    let refAreaLeftCopy = refAreaLeft;
    let refAreaRightCopy = refAreaRight;

    if (refAreaLeft > refAreaRight) {
      [refAreaLeftCopy, refAreaRightCopy] = [refAreaRight, refAreaLeft];
    }

    // Slice the data
    const earliestDateInclusive = new Date(refAreaLeftCopy);
    const latestDateInclusive = new Date(refAreaRightCopy);
    const filteredSlice = resp.searchPhraseRankTimeSeries.filter((entry) => {
      const x = new Date(entry.date);
      return earliestDateInclusive <= x && x <= latestDateInclusive;
    });
    setDataSlice(filteredSlice);

    // TODO == add some logic for a new sort
  };

  const filterDataByStartAndEnd = (start: string, end: string) => {
    const earliestDateInclusive =
      start === 'dataMin' ? new Date(resp.searchPhraseRankTimeSeries[0].date) : new Date(start);
    const latestDateInclusive =
      end === 'dataMax'
        ? new Date(resp.searchPhraseRankTimeSeries[resp.searchPhraseRankTimeSeries.length - 1].date)
        : new Date(end);

    const filteredSlice = resp.searchPhraseRankTimeSeries.filter((entry) => {
      const x = new Date(entry.date);
      return earliestDateInclusive <= x && x <= latestDateInclusive;
    });
    setDataSlice(filteredSlice);
  };

  const setZoom = (timeFrame: TimeFrame) => {
    // TODO -- FIX THIS
    const startByTimeFrame: Record<TimeFrame, string> = {
      [TimeFrame.RESET]: 'dataMin',
      [TimeFrame.LAST_30_DAYS]: 'dataMin',
      [TimeFrame.LAST_90_DAYS]: 'dataMin',
      [TimeFrame.LAST_WINTER]: 'dataMin',
      [TimeFrame.LAST_SPRING]: '2023-03-25T00:00:00.000Z',
      [TimeFrame.LAST_SUMMER]: '2023-06-24T00:00:00.000Z',
      [TimeFrame.LAST_FALL]: '2023-09-09T00:00:00.000Z',
      [TimeFrame.THIS_MONTH_LAST_YEAR]: 'dataMin',
    };

    const endByTimeFrame: Record<TimeFrame, string> = {
      [TimeFrame.RESET]: 'dataMax',
      [TimeFrame.LAST_30_DAYS]: 'dataMax',
      [TimeFrame.LAST_90_DAYS]: 'dataMax',
      [TimeFrame.LAST_WINTER]: '2023-03-25T00:00:00.000Z',
      [TimeFrame.LAST_SPRING]: '2023-06-24T00:00:00.000Z',
      [TimeFrame.LAST_SUMMER]: '2023-09-09T00:00:00.000Z',
      [TimeFrame.LAST_FALL]: '2023-11-11T00:00:00.000Z',
      [TimeFrame.THIS_MONTH_LAST_YEAR]: 'dataMax',
    };

    setLeft(startByTimeFrame[timeFrame]);
    setRight(endByTimeFrame[timeFrame]);
    filterDataByStartAndEnd(startByTimeFrame[timeFrame], endByTimeFrame[timeFrame]);
  };

  return (
    <>
      <LineGraphHeightController>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={dataSlice}
            onMouseDown={(e) => {
              if (!e || !e.activeLabel) {
                return;
              }
              setRefAreaLeft(e.activeLabel);
            }}
            onMouseMove={(e) => {
              if (refAreaLeft && e) {
                setRefAreaRight(e.activeLabel);
              }
            }}
            onMouseUp={zoom}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis
              dataKey="date"
              tickFormatter={(d) => new Date(d).toLocaleDateString()}
              fontSize={10}
              domain={[left, right]}
              style={{
                userSelect: 'none',
              }}
            />
            <YAxis
              fontSize={10}
              width={18}
              tick={{ dx: 0 }}
              domain={[Math.floor(minY) - 3, 100]}
              style={{
                userSelect: 'none',
              }}
            />
            {/*<Tooltip*/}
            {/*  labelFormatter={(d: string) => {*/}
            {/*    return new Date(d).toLocaleDateString();*/}
            {/*  }}*/}
            {/*  formatter={(value: number, name: string, props: any) => {*/}
            {/*    return [value.toFixed(1), name, props];*/}
            {/*  }}*/}
            {/*/>*/}
            {refAreaLeft && refAreaRight ? (
              <ReferenceArea x1={refAreaLeft} x2={refAreaRight} />
            ) : null}
            <Legend wrapperStyle={{ fontSize: '8px', fontWeight: 'bold' }} />
            {/* @ts-ignore*/}
            {selectedSearchPhrasesArray.map((phrase, index) => {
              return (
                <Line
                  stroke={strokes[index]}
                  type="monotone"
                  dataKey={phrase}
                  strokeWidth={2}
                  animationDuration={0}
                  key={index}
                  dot={false}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      </LineGraphHeightController>
      <ChartControlSection>
        <Typography
          level={'body-xs'}
          sx={{
            marginBottom: '10px',
          }}
        >
          Click and drag to select a custom timeframe. You can also choose a preset.
        </Typography>
        {allTimeFrames.map((timeFrame) => {
          return (
            <Button
              variant={'outlined'}
              onClick={() => {
                setZoom(timeFrame);
              }}
              size={'sm'}
              sx={{
                marginLeft: '5px',
                fontSize: '10px',
                padding: '5px 10px',
                minHeight: '30px',
                height: '30px',
                fontWeight: 500,
                marginBottom: '5px',
              }}
            >
              {timeFrame}
            </Button>
          );
        })}
      </ChartControlSection>
    </>
  );
};
