import React from 'react';
import { createContext, useState } from 'react';

export type Notification = {
  message: string;
};

export const NotificationContext = createContext({});

export function NotificationProvider(props: any) {
  const [notification, setNotification] = useState<string | undefined>();
  const [isError, setIsError] = useState<boolean>(false);

  const showNotification = (message: string, isError?: boolean) => {
    setNotification(message);
    setIsError(isError ?? false);
  };

  return (
    <NotificationContext.Provider
      value={{
        notification,
        showNotification,
        isError,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
}

export const useNotification = (): {
  notification: string | undefined;
  showNotification: (message?: string, isError?: boolean) => void;
  isError: boolean;
} => {
  const context = React.useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('NotificationContext must be within NotificationProvider');
  }

  // @ts-ignore
  return context;
};
