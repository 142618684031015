export enum Channel {
  AMAZON = 'AMAZON',
  // WALMART = 'WALMART',
  // TARGET = 'TARGET',
}

export type CreateOptimizationResponse = {
  optimizationId: string;
  singleChannelOptimizationIdByChannel: Partial<Record<Channel, string>>;
  searchPhrases: Array<string>;
};

export enum OptimizationStatus {
  REQUESTED = 'REQUESTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export type ReferenceProductListing = {
  title: string;
  description: string;
  bulletPoints: Array<string>;
  productUrl: string;
  productIdentifierType: string;
  productIdentifier?: string;
};

export type SingleChannelOptimizedListing = {
  id: number;
  searchPhrases: Array<string>;
  prompt: string;
  optimizationStatus: OptimizationStatus;
  title?: string;
  description?: string;
  bulletPoints?: Array<string>;
  rationale?: string;
  channel: Channel;
  error?: string;
  referenceProductListings?: Array<ReferenceProductListing>;
};

export type GetOptimizationResponse = {
  id: number;
  asin: string;
  searchPhrases: Array<string>;
  channels: Array<string>;
  singleChannelOptimizedListingByChannel: Partial<Record<Channel, SingleChannelOptimizedListing>>;
};

export type TimeSeriesData =
  | {
      date: string;
    }
  | { [key: string]: number };

// some sort of score based on?
export type SearchPhraseData = {
  relevanceScore: number;
  searchFrequencyScore: number;
};

export type KeywordResearchResponse = {
  searchPhrases: Array<string>;
  searchPhraseRankTimeSeries: Array<TimeSeriesData>;
  searchPhraseData: Record<string, SearchPhraseData>;
};

export type GetOptimizerPageCacheResponse = {
  keywordResearch: KeywordResearchResponse;
  optimization: GetOptimizationResponse;
};

export enum RegenerateFieldName {
  TITLE = 'TITLE',
  DESCRIPTION = 'DESCRIPTION',
  BULLET_POINTS = 'BULLET_POINTS',
}

export type RegenerateFieldRequest = {
  singleChannelId: number;
  fieldName: RegenerateFieldName;
  feedback: string;
};

export type RegenerateFieldResponse = {
  status: 'success' | 'error';
};

export type CacheMetadata = {
  cacheId: string;
  imageUrl: string;
  title: string;
  asin: string;
  channels: Array<Channel>;
  createdAt: string;
};

export type OptimizationHistoryResponse = {
  history: Array<CacheMetadata>;
};

export type GetUsageResponse = {
  keywordPerformance: {
    used: number;
    limit: number;
  };
  productOptimization: {
    used: number;
    limit: number;
  };
};

export const randomASINs = [
  'B09NYFJYTP', // Done
  'B08ZJQZ7N1', // Done
  'B01M3MUR7H', // Done
  'B0BJMV9BXJ', // Done
  'B074XPNS3Z', // Done
  'B0865RLTXB', // Done
];

export type GetReferenceProductListingsResponse = {
  referenceListings: Array<ReferenceProductListing>;
};

export type FineTuneModel = {
  id: number;
  name: string;
  description: string;
  baseModel?: string;
  modelId: string;
  rawModelSpeciality: string;
  createdAt: string;
};

export type GetRelevantModelsResponse = {
  models: Array<FineTuneModel>;
};

// Represents the percentage of results that PASS each check
export type PromptIterationEvaluationPassAggregation = {
  titleLength: number;
  titleLengthAvgScore?: number;
  descriptionLength: number;
  descriptionLengthAvgScore?: number;
  bulletPointsLength?: number;
  bulletPointLengthAvgScore: number;
  numBulletPoints: number;
  titleCase: number;
  startsWithBrand: number;
  noSpecialCharacters: number;
};

export type EvaluationMetadata = {
  pass: boolean;
  metadata: string | number | Array<string> | Array<number> | Record<string, unknown>;
  score?: number;
};

export type ObjectiveEvaluation = {
  titleLength: EvaluationMetadata;
  descriptionLength: EvaluationMetadata;
  bulletPointsLength: EvaluationMetadata;
  numBulletPoints: EvaluationMetadata;
  titleCase: EvaluationMetadata;
  startsWithBrand: EvaluationMetadata;
  noSpecialCharacters: EvaluationMetadata;
  unitAbbreviations: EvaluationMetadata;
};

export type OptimizedListing = {
  title: string;
  description: string;
  bulletPoints: Array<string>;
};

export type AmazonProduct = {
  asin: string;
  mainPhotoUrl: string;
  title: string;
  description: string;
  brand: string;
  bulletPoints: Array<string>;
  attributes: Record<string, any>;
};

export type ProductDiff = {
  semanticTitle: number;
  semanticDescription: number;
  semanticBulletPoints: number;
  syntacticTitle: number;
};

// TODO -- name this
export type PDPContentType = 'title' | 'description' | 'bulletPoints';

// TODO -- name this
export type IterationPurpose = 'initialGeneration' | 'objectiveRefinement' | 'subjectiveRefinement';

export type IterativeGenerationNode = {
  contentType: PDPContentType;
  input: string;
  systemMessage: string;
  prompt: string;
  response: string | Array<string>;
  purpose: IterationPurpose;
  subPurpose?: string;
};

export type PromptIterationRunRow = {
  asin: string;
  amazonProduct: AmazonProduct;
  selectedPhrases: Array<string>;
  exampleListings: Array<ReferenceProductListing>;
  renderedPrompt: string;
  rawResponse: string;
  parsedResponse: OptimizedListing;
  objectiveQualityMetrics?: {
    failedChecks: Array<string>;
    evaluation: ObjectiveEvaluation;
  };
  titleChain?: Array<IterativeGenerationNode>;
  descriptionChain?: Array<IterativeGenerationNode>;
  bulletPointsChain?: Array<IterativeGenerationNode>;
};

export type PromptIterationResults = {
  rows: Array<PromptIterationRunRow>;
};

export type PromptIterationRun = {
  id: number;
  systemMessage: string; // Deprecated
  prompt: string; // Deprecated
  titleSystemMessage: string | null;
  titlePrompt: string | null;
  descriptionSystemMessage: string | null;
  descriptionPrompt: string | null;
  bulletPointsSystemMessage: string | null;
  bulletPointsPrompt: string | null;
  modelId: string;
  temperature: number;
  maximumLength: number;
  topP: number;
  frequencyPenalty: number;
  presencePenalty: number;
  asins: Array<string>;
  numCompleted: number;
  status: string;
  results?: PromptIterationResults;
  objectiveResultsAggregation?: any;
  notes?: string;
  error?: Array<string>;
  createdAt: string;
};

// Post Processing Iteration
export type CreatePostProcessingIterationReq = {
  promptIterationRunId: number;
  lengthenTitleSystemMessage: string;
  lengthenTitlePrompt: string;
  lengthenDescriptionSystemMessage: string;
  lengthenDescriptionPrompt: string;
  lengthenBulletPointSystemMessage: string;
  lengthenBulletPointPrompt: string;
  shortenTitleSystemMessage: string;
  shortenTitlePrompt: string;
  shortenDescriptionSystemMessage: string;
  shortenDescriptionPrompt: string;
  shortenBulletPointSystemMessage: string;
  shortenBulletPointPrompt: string;
};

export type PostProcessingIterationRun = {
  id: number;
  notes: string;
  results?: PromptIterationResults;
  objectiveResultsAggregation?: any;
};

export type UserSavedAmazonListing = {
  id: number;
  userId: number;
  asin: string;
  title: string;
  imageUrl: string;
  lastFetchedAt: string;
  createdAt: string;
};

export type GetUserSavedAmazonListingResponse = {
  savedProducts: Array<UserSavedAmazonListing>;
};

export type OptimizationMetadata = {
  id: number;
  asin: string;
  searchPhrases: Array<string>;
  createdAt: string;
  optimizationStatus: OptimizationStatus;
  title?: string;
};

export type GetOptimizationsForAsinResponse = {
  optimizations: Array<OptimizationMetadata>;
};
