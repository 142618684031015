export const mockInputProduct = {
  asin: 'B07B6RZP4H',
  brand: 'KATCHY',
  title:
    'Katchy Indoor Insect Trap - Catcher & Killer for Mosquitos, Gnats, Moths, Fruit Flies - Non-Zapper Traps for Inside Your Home - Catch Insects Indoors with Suction, Bug Light & Sticky Glue (Black)',
  attributes: {
    size: [{ value: 'Normal', language_tag: 'en_US', marketplace_id: 'ATVPDKIKX0DER' }],
    brand: [{ value: 'KATCHY', language_tag: 'en_US', marketplace_id: 'ATVPDKIKX0DER' }],
    color: [{ value: 'Black', language_tag: 'en_US', marketplace_id: 'ATVPDKIKX0DER' }],
    style: [{ value: 'Original', language_tag: 'en_US', marketplace_id: 'ATVPDKIKX0DER' }],
    material: [{ value: 'Paper', language_tag: 'en_US', marketplace_id: 'ATVPDKIKX0DER' }],
    item_name: [
      {
        value:
          'Katchy Indoor Insect Trap - Catcher & Killer for Mosquitos, Gnats, Moths, Fruit Flies - Non-Zapper Traps for Inside Your Home - Catch Insects Indoors with Suction, Bug Light & Sticky Glue (Black)',
        language_tag: 'en_US',
        marketplace_id: 'ATVPDKIKX0DER',
      },
    ],
    item_shape: [{ value: 'Rectangular', language_tag: 'en_US', marketplace_id: 'ATVPDKIKX0DER' }],
    list_price: [{ value: 47.99, currency: 'USD', marketplace_id: 'ATVPDKIKX0DER' }],
    model_name: [
      {
        value: 'Bed Bug Trap Insect Interceptor',
        language_tag: 'en_US',
        marketplace_id: 'ATVPDKIKX0DER',
      },
    ],
    unit_count: [
      {
        type: { value: 'Count', language_tag: 'en_US' },
        value: 1,
        marketplace_id: 'ATVPDKIKX0DER',
      },
    ],
    is_electric: [{ value: true, marketplace_id: 'ATVPDKIKX0DER' }],
    item_weight: [{ unit: 'pounds', value: 1.37, marketplace_id: 'ATVPDKIKX0DER' }],
    part_number: [{ value: 'B07B6RZP4H', marketplace_id: 'ATVPDKIKX0DER' }],
    street_date: [{ value: '2020-11-25T00:00:01Z', marketplace_id: 'ATVPDKIKX0DER' }],
    bullet_point: [
      {
        value:
          'Triple trapping power: a UV light, powerful fan, and sticky glue board combines to be an effective way for how to get rid of fruit flies, gnats, and other small bugs. Simply turn on/off when needed.',
        language_tag: 'en_US',
        marketplace_id: 'ATVPDKIKX0DER',
      },
      {
        value:
          'Use the fruit fly trap for indoors close to insect-ridden fruit, plants or trash bin. Turn off the lights for best results.',
        language_tag: 'en_US',
        marketplace_id: 'ATVPDKIKX0DER',
      },
      {
        value:
          'No more ugly traps! Subtle and stylish; easily place in your home, kitchen, or office as a decorative piece. No zapper needed.',
        language_tag: 'en_US',
        marketplace_id: 'ATVPDKIKX0DER',
      },
      {
        value:
          'Not effective on house flies. Is effective in killing fruit flies, gnats, and mosquitos.',
        language_tag: 'en_US',
        marketplace_id: 'ATVPDKIKX0DER',
      },
      {
        value:
          'Easy to use, choose between standard or low speed setting and leave it to work its magic, EPA Est. Numb.:93372-CHN-1',
        language_tag: 'en_US',
        marketplace_id: 'ATVPDKIKX0DER',
      },
    ],
    manufacturer: [{ value: 'KATCHY', language_tag: 'en_US', marketplace_id: 'ATVPDKIKX0DER' }],
    model_number: [{ value: 'B07B6RZP4H', marketplace_id: 'ATVPDKIKX0DER' }],
    item_type_name: [
      { value: 'Pack of 8, 8 Pack', language_tag: 'en_US', marketplace_id: 'ATVPDKIKX0DER' },
    ],
    item_dimensions: [
      {
        width: { unit: 'inches', value: 5.5 },
        height: { unit: 'inches', value: 9.1 },
        length: { unit: 'inches', value: 5.5 },
        marketplace_id: 'ATVPDKIKX0DER',
      },
    ],
    variation_theme: [{ name: 'STYLE_NAME/COLOR_NAME', marketplace_id: 'ATVPDKIKX0DER' }],
    number_of_pieces: [{ value: 1, marketplace_id: 'ATVPDKIKX0DER' }],
    item_type_keyword: [{ value: 'home-pest-control-traps', marketplace_id: 'ATVPDKIKX0DER' }],
    pesticide_marking: [
      {
        marking_type: 'epa_establishment_number',
        marketplace_id: 'ATVPDKIKX0DER',
        registration_status: 'fifra_registration_required',
        certification_number: '93372-CHN-1',
      },
      {
        marking_type: 'epa_establishment_number',
        marketplace_id: 'ATVPDKIKX0DER',
        registration_status: 'fifra_registration_required',
        certification_number: '93372-CHN-1',
      },
      {
        marking_type: 'epa_establishment_number',
        marketplace_id: 'ATVPDKIKX0DER',
        registration_status: 'fifra_registration_required',
        certification_number: '93372-CHN-1',
      },
    ],
    power_source_type: [
      { value: 'Corded Electric', language_tag: 'en_US', marketplace_id: 'ATVPDKIKX0DER' },
    ],
    batteries_included: [{ value: false, marketplace_id: 'ATVPDKIKX0DER' }],
    batteries_required: [{ value: false, marketplace_id: 'ATVPDKIKX0DER' }],
    item_package_weight: [{ unit: 'kilograms', value: 0.63, marketplace_id: 'ATVPDKIKX0DER' }],
    is_assembly_required: [{ value: false, marketplace_id: 'ATVPDKIKX0DER' }],
    item_package_quantity: [{ value: 1, marketplace_id: 'ATVPDKIKX0DER' }],
    item_display_dimensions: [
      {
        width: { unit: 'centimeters', value: 15 },
        height: { unit: 'centimeters', value: 10 },
        length: { unit: 'centimeters', value: 15 },
        marketplace_id: 'ATVPDKIKX0DER',
      },
    ],
    item_package_dimensions: [
      {
        width: { unit: 'centimeters', value: 13.8938 },
        height: { unit: 'centimeters', value: 28.5 },
        length: { unit: 'centimeters', value: 13.7922 },
        marketplace_id: 'ATVPDKIKX0DER',
      },
    ],
    contains_liquid_contents: [{ value: false, marketplace_id: 'ATVPDKIKX0DER' }],
    item_length_width_height: [
      {
        width: { unit: 'inches', value: 4.3 },
        height: { unit: 'inches', value: 0.2 },
        length: { unit: 'inches', value: 4.3 },
        marketplace_id: 'ATVPDKIKX0DER',
      },
    ],
    is_expiration_dated_product: [{ value: false, marketplace_id: 'ATVPDKIKX0DER' }],
    supplier_declared_dg_hz_regulation: [
      { value: 'not_applicable', marketplace_id: 'ATVPDKIKX0DER' },
    ],
    externally_assigned_product_identifier: [
      { type: 'upc', value: '742833225518', marketplace_id: 'ATVPDKIKX0DER' },
      { type: 'ean', value: '0742833225518', marketplace_id: 'ATVPDKIKX0DER' },
      { type: 'gtin', value: '00742833225518', marketplace_id: 'ATVPDKIKX0DER' },
    ],
  },
  description: '',
  bulletPoints: [
    'Triple trapping power: a UV light, powerful fan, and sticky glue board combines to be an effective way for how to get rid of fruit flies, gnats, and other small bugs. Simply turn on/off when needed.',
    'Use the fruit fly trap for indoors close to insect-ridden fruit, plants or trash bin. Turn off the lights for best results.',
    'No more ugly traps! Subtle and stylish; easily place in your home, kitchen, or office as a decorative piece. No zapper needed.',
    'Not effective on house flies. Is effective in killing fruit flies, gnats, and mosquitos.',
    'Easy to use, choose between standard or low speed setting and leave it to work its magic, EPA Est. Numb.:93372-CHN-1',
  ],
  mainPhotoUrl: 'https://m.media-amazon.com/images/I/51DZkCOTfBL.jpg',
};

export const mockSearchPhrases = ['indoor fly trap', 'automatic fly trap', 'fly trap', 'flytrap'];

export const mockExampleProducts = [
  {
    title:
      'Safer Home SH502 Indoor Plug-In Fly Trap for Flies, Fruit Flies, Moths, Gnats, and Other Flying Insects – 400 Sq Ft of Protection',
    description: '',
    bulletPoints: [
      'Works Fast – The trap starts working immediately to attract, trap, and kill flies, fruit flies, moths, gnats, and more',
      'Contains no chemical insecticides',
      'Ideal for Your Home – These traps are the perfect choice for homes with children and pets',
      'Protects Large Rooms – The UV LED light naturally attracts flying insects to the trap to protect large rooms',
      'Discreet Design – Trapped insects are hidden on the back of the glue card, while the trendy design blends in with your décor',
      'Easy to Use and Reusable – Simply insert a glue card and plug the trap in. The trap rotates to fit any indoor outlet. Refill glue cards are available for continued insect protection',
    ],
  },
  {
    title:
      'KATCHY Indoor Insect Trap: Bug, Fruit Fly, Gnat, Mosquito Killer - UV Light, Fan, Sticky Glue Boards Trap Even The Tiniest Flying Bugs - No Zapper- (Black)',
    description:
      "Triple trapping power: works best for catching gnats, fruit flies and mosquitoes. The UV light lures them above the fan that sucks them in and the super sticky glue boards make sure they don't escape. The trap is not as effective for house flies!",
    bulletPoints: [
      "Triple trapping power: works best for catching gnats, fruit flies and mosquitoes. The UV light lures them above the fan that sucks them in and the super sticky glue boards make sure they don't escape. Be warned that the trap is not as effective for house flies - especially killing the bigger ones!",
      "Reduces bugs, but isn't a complete insect killer lamp, shouldn't replace system. Not for house flies.",
      'Easy to use: with a fully enclosed design, simply plug in the USB cable and watch the flies get caught. ',
      'For indoor use, or outdoors where unit will stay dry. Perfect for kitchens and dining rooms. ',
      'Beautiful design to blend into home decor. ',
      'Not for house flies, fruit flies and gnats are attracted to the trap. ',
    ],
  },
  {
    title:
      'Safer Home SH502 Indoor Plug-In Fly Trap for Flies, Fruit Flies, Moths, Gnats, and Other Flying Insects – 400 Sq Ft of Protection',
    description: '',
    bulletPoints: [
      'Works Fast – The trap starts working immediately to attract, trap, and kill flies, fruit flies, moths, gnats, and more',
      'Contains no chemical insecticides',
      'Ideal for Your Home – These traps are the perfect choice for homes with children and pets',
      'Protects Large Rooms – The UV LED light naturally attracts flying insects to the trap to protect large rooms',
      'Discreet Design – Trapped insects are hidden on the back of the glue card, while the trendy design blends in with your décor',
      'Easy to Use and Reusable – Simply insert a glue card and plug the trap in. The trap rotates to fit any indoor outlet. Refill glue cards are available for continued insect protection',
    ],
  },
  {
    title:
      'KATCHY Indoor Insect Trap: Bug, Fruit Fly, Gnat, Mosquito Killer - UV Light, Fan, Sticky Glue Boards Trap Even The Tiniest Flying Bugs - No Zapper- (Black)',
    description:
      "Triple trapping power: works best for catching gnats, fruit flies and mosquitoes. The UV light lures them above the fan that sucks them in and the super sticky glue boards make sure they don't escape. The trap is not as effective for house flies!",
    bulletPoints: [
      "Triple trapping power: works best for catching gnats, fruit flies and mosquitoes. The UV light lures them above the fan that sucks them in and the super sticky glue boards make sure they don't escape. Be warned that the trap is not as effective for house flies - especially killing the bigger ones!",
      "Reduces bugs, but isn't a complete insect killer lamp, shouldn't replace system. Not for house flies.",
      'Easy to use: with a fully enclosed design, simply plug in the USB cable and watch the flies get caught. ',
      'For indoor use, or outdoors where unit will stay dry. Perfect for kitchens and dining rooms. ',
      'Beautiful design to blend into home decor. ',
      'Not for house flies, fruit flies and gnats are attracted to the trap. ',
    ],
  },
  {
    title:
      'Safer Home SH502 Indoor Plug-In Fly Trap for Flies, Fruit Flies, Moths, Gnats, and Other Flying Insects – 400 Sq Ft of Protection',
    description: '',
    bulletPoints: [
      'Works Fast – The trap starts working immediately to attract, trap, and kill flies, fruit flies, moths, gnats, and more',
      'Contains no chemical insecticides',
      'Ideal for Your Home – These traps are the perfect choice for homes with children and pets',
      'Protects Large Rooms – The UV LED light naturally attracts flying insects to the trap to protect large rooms',
      'Discreet Design – Trapped insects are hidden on the back of the glue card, while the trendy design blends in with your décor',
      'Easy to Use and Reusable – Simply insert a glue card and plug the trap in. The trap rotates to fit any indoor outlet. Refill glue cards are available for continued insect protection',
    ],
  },
  {
    title:
      'KATCHY Indoor Insect Trap: Bug, Fruit Fly, Gnat, Mosquito Killer - UV Light, Fan, Sticky Glue Boards Trap Even The Tiniest Flying Bugs - No Zapper- (Black)',
    description:
      "Triple trapping power: works best for catching gnats, fruit flies and mosquitoes. The UV light lures them above the fan that sucks them in and the super sticky glue boards make sure they don't escape. The trap is not as effective for house flies!",
    bulletPoints: [
      "Triple trapping power: works best for catching gnats, fruit flies and mosquitoes. The UV light lures them above the fan that sucks them in and the super sticky glue boards make sure they don't escape. Be warned that the trap is not as effective for house flies - especially killing the bigger ones!",
      "Reduces bugs, but isn't a complete insect killer lamp, shouldn't replace system. Not for house flies.",
      'Easy to use: with a fully enclosed design, simply plug in the USB cable and watch the flies get caught. ',
      'For indoor use, or outdoors where unit will stay dry. Perfect for kitchens and dining rooms. ',
      'Beautiful design to blend into home decor. ',
      'Not for house flies, fruit flies and gnats are attracted to the trap. ',
    ],
  },
  {
    title:
      'Safer Home SH502 Indoor Plug-In Fly Trap for Flies, Fruit Flies, Moths, Gnats, and Other Flying Insects – 400 Sq Ft of Protection',
    description: '',
    bulletPoints: [
      'Works Fast – The trap starts working immediately to attract, trap, and kill flies, fruit flies, moths, gnats, and more',
      'Contains no chemical insecticides',
      'Ideal for Your Home – These traps are the perfect choice for homes with children and pets',
      'Protects Large Rooms – The UV LED light naturally attracts flying insects to the trap to protect large rooms',
      'Discreet Design – Trapped insects are hidden on the back of the glue card, while the trendy design blends in with your décor',
      'Easy to Use and Reusable – Simply insert a glue card and plug the trap in. The trap rotates to fit any indoor outlet. Refill glue cards are available for continued insect protection',
    ],
  },
  {
    title:
      'KATCHY Indoor Insect Trap: Bug, Fruit Fly, Gnat, Mosquito Killer - UV Light, Fan, Sticky Glue Boards Trap Even The Tiniest Flying Bugs - No Zapper- (Black)',
    description:
      "Triple trapping power: works best for catching gnats, fruit flies and mosquitoes. The UV light lures them above the fan that sucks them in and the super sticky glue boards make sure they don't escape. The trap is not as effective for house flies!",
    bulletPoints: [
      "Triple trapping power: works best for catching gnats, fruit flies and mosquitoes. The UV light lures them above the fan that sucks them in and the super sticky glue boards make sure they don't escape. Be warned that the trap is not as effective for house flies - especially killing the bigger ones!",
      "Reduces bugs, but isn't a complete insect killer lamp, shouldn't replace system. Not for house flies.",
      'Easy to use: with a fully enclosed design, simply plug in the USB cable and watch the flies get caught. ',
      'For indoor use, or outdoors where unit will stay dry. Perfect for kitchens and dining rooms. ',
      'Beautiful design to blend into home decor. ',
      'Not for house flies, fruit flies and gnats are attracted to the trap. ',
    ],
  },
  {
    title:
      'Safer Home SH502 Indoor Plug-In Fly Trap for Flies, Fruit Flies, Moths, Gnats, and Other Flying Insects – 400 Sq Ft of Protection',
    description: '',
    bulletPoints: [
      'Works Fast – The trap starts working immediately to attract, trap, and kill flies, fruit flies, moths, gnats, and more',
      'Contains no chemical insecticides',
      'Ideal for Your Home – These traps are the perfect choice for homes with children and pets',
      'Protects Large Rooms – The UV LED light naturally attracts flying insects to the trap to protect large rooms',
      'Discreet Design – Trapped insects are hidden on the back of the glue card, while the trendy design blends in with your décor',
      'Easy to Use and Reusable – Simply insert a glue card and plug the trap in. The trap rotates to fit any indoor outlet. Refill glue cards are available for continued insect protection',
    ],
  },
  {
    title:
      'KATCHY Indoor Insect Trap: Bug, Fruit Fly, Gnat, Mosquito Killer - UV Light, Fan, Sticky Glue Boards Trap Even The Tiniest Flying Bugs - No Zapper- (Black)',
    description:
      "Triple trapping power: works best for catching gnats, fruit flies and mosquitoes. The UV light lures them above the fan that sucks them in and the super sticky glue boards make sure they don't escape. The trap is not as effective for house flies!",
    bulletPoints: [
      "Triple trapping power: works best for catching gnats, fruit flies and mosquitoes. The UV light lures them above the fan that sucks them in and the super sticky glue boards make sure they don't escape. Be warned that the trap is not as effective for house flies - especially killing the bigger ones!",
      "Reduces bugs, but isn't a complete insect killer lamp, shouldn't replace system. Not for house flies.",
      'Easy to use: with a fully enclosed design, simply plug in the USB cable and watch the flies get caught. ',
      'For indoor use, or outdoors where unit will stay dry. Perfect for kitchens and dining rooms. ',
      'Beautiful design to blend into home decor. ',
      'Not for house flies, fruit flies and gnats are attracted to the trap. ',
    ],
  },
];
