import React, { useEffect } from 'react';
import './App.css';
import FullLayout from './components/FullLayout';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorProvider } from './components/ErrorProvider';
import { ErrorBar } from './components/ErrorBar';
import Login from './features/auth/LoginPage';
import Register from './features/auth/RegisterPage';
import { AuthProvider } from './components/auth/AuthProvider';
import { ListingOptimizer } from './features/ListingOptimizer/ListingOptimizer';
import { UsagePage } from './features/Usage/UsagePage';
import { History } from './features/History/History';
import { SubmitUserInfo } from './features/SubmitUserInfo/SubmitUserInfo';
import { NotificationProvider } from './components/NotificationProvider';
import { Notifications } from './components/Notifications';
import { InternalTextDistancePage } from './features/InternalTextDistance/InternalTextDistancePage';
import { InternalPromptIterator } from './features/InternalPromptIterator/InternalPromptIterator';
import { SinglePromptIteration } from './features/InternalPromptIterator/SinglePromptIteration';
import { PostProcessingIterator } from './features/InternalPromptIterator/PostProcessingIterator';
import { SinglePostProcessingIteration } from './features/InternalPromptIterator/SinglePostProcessingIteration';
import { ListingOptimizerV2Wrapper } from './features/ListingOptimizerV2/ListingOptimizerV2Wrapper';
import { PurchaseCredits } from './features/PurchaseCredits/PurchaseCredits';
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';
import { CssVarsProvider as JoyCssVarsProvider } from '@mui/joy/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { extendTheme } from '@mui/joy';
import { LicenseInfo } from '@mui/x-license-pro';
import { KeywordResearch } from './features/KeywordResearch/KeywordResearch';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { WBMMileageSelector } from './features/WBM/WBMMileageSelector';

LicenseInfo.setLicenseKey(
  'f24741fa4893f659ad94eee5313e96d2Tz04MzY1NixFPTE3Mzg3ODAwNjUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});

function withLayout(Component: React.ComponentType, pageTitle: string) {
  return (
    <FullLayout pageTitle={pageTitle}>
      <Component />
    </FullLayout>
  );
}

function RedirectToOptimizer() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/keywords');
  }, []);

  return <div />;
}

const materialTheme = materialExtendTheme();

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: '#C0D4DB',
          100: '#9ABAC5',
          200: '#74A0AF',
          300: '#4E8799',
          400: '#286D83',
          500: '#02536D', // This is the main
          600: '#004963',
          700: '#003D55',
          800: '#002D41',
          900: '#001C29',
        },
      },
    },
  },
});

function App() {
  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <NotificationProvider>
              <ErrorProvider>
                <BrowserRouter>
                  <QueryParamProvider adapter={ReactRouter6Adapter}>
                    <Routes>
                      <Route
                        path={'/keywords'}
                        element={withLayout(KeywordResearch, 'Keyword Research')}
                      />
                      <Route
                        path={'/keywords/:asin'}
                        element={withLayout(KeywordResearch, 'Keyword Research')}
                      />
                      <Route path={'/login'} element={<Login />} />
                      <Route path={'/register'} element={<Register />} />
                      <Route
                        path={'/optimizer'}
                        element={withLayout(ListingOptimizerV2Wrapper, 'Listing Optimize')}
                      />
                      <Route
                        path={'/optimizer/:asin'}
                        element={withLayout(ListingOptimizerV2Wrapper, 'Listing Optimizer')}
                      />
                      <Route
                        path={'/optimizer/:asin/opt/:optimizationId'}
                        element={withLayout(ListingOptimizerV2Wrapper, 'Listing Optimizer')}
                      />
                      <Route
                        path={'/keywords'}
                        element={withLayout(UsagePage, 'Keyword Research')}
                      />
                      {/*<Route path={'/optimizer'} element={withLayout(ListingOptimizer, 'Listing Optimizer')} />*/}
                      <Route
                        path={'/'}
                        element={withLayout(RedirectToOptimizer, 'Listing Optimizer')}
                      />
                      <Route path={'/usage'} element={withLayout(UsagePage, 'Usage')} />
                      {/*<Route path={'/history'} element={withLayout(History, 'History')} />*/}
                      <Route
                        path={'/userInfo'}
                        element={withLayout(SubmitUserInfo, 'Submit User Info')}
                      />
                      <Route
                        path={'/distance'}
                        element={withLayout(InternalTextDistancePage, 'Text Distance Tool')}
                      />
                      <Route
                        path={'/prompt'}
                        element={withLayout(InternalPromptIterator, 'Prompt Iteration Tool')}
                      />
                      <Route
                        path={'/prompt/:iterationId'}
                        element={withLayout(SinglePromptIteration, 'Prompt Iteration Tool')}
                      />
                      <Route
                        path={'postProcessing'}
                        element={withLayout(PostProcessingIterator, 'Post Processing')}
                      />
                      <Route
                        path={'/postProcessing/:iterationId'}
                        element={withLayout(SinglePostProcessingIteration, 'Post Processing')}
                      />
                      <Route
                        path={'/credits'}
                        element={withLayout(PurchaseCredits, 'Listing Optimizer')}
                      />
                      <Route path={'/wbm'} element={<WBMMileageSelector />} />
                    </Routes>
                  </QueryParamProvider>
                </BrowserRouter>
                <ErrorBar />
                <Notifications />
              </ErrorProvider>
            </NotificationProvider>
          </AuthProvider>
        </QueryClientProvider>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  );
}

export default App;
