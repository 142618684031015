import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../components/auth/authHooks';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { PageBlockTitle, PageBlockWrapper } from '../../components/Common';

const LimitsSection = styled.div`
  display: flex;
`;

const LimitBlockWrapper = styled.div`
  position: relative;
  margin: 10px;
  border: 1px solid #e0e0e0;
  padding: 20px;
  width: 250px;
  display: flex;
  flex-direction: column;
`;

const LimitBlockTitle = styled.span`
  font-weight: bold;
  font-size: 18px;
`;

const RemainingCredits = styled.span`
  font-size: 48px;
  text-align: center;
  margin-top: 20px;
`;

const RemainingLoader = styled.div`
  position: absolute;
  width: 120px;
  height: 50px;

  // Add animated gradient background
  background: linear-gradient(90deg, #ccc, #fff, #ccc);

  background-size: 400% 100%;
  animation: AnimationName 1.5s linear infinite;

  top: 85px;
  left: 50%;
  transform: translateX(-50%);

  @keyframes AnimationName {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: -33% 50%;
    }
  }
`;

const TotalCredits = styled.span`
  font-size: 18px;
  text-align: right;
`;

export const LimitBlock = ({
  title,
  remaining,
  loading,
}: {
  title: string;
  remaining?: string;
  loading: boolean;
}) => {
  return (
    <LimitBlockWrapper>
      <LimitBlockTitle>{title}</LimitBlockTitle>
      <RemainingCredits>{remaining ?? 'load'}</RemainingCredits>
      {loading && <RemainingLoader />}
    </LimitBlockWrapper>
  );
};

export const UsagePage = () => {
  const { user } = useAuth();

  const fetchUsageData = async () => {
    const resp = await axios.get(`/api/users/${user.id}/usage`);
    return resp.data;
  };

  const { data, isLoading } = useQuery(['usage', user.id], fetchUsageData);

  return (
    <PageBlockWrapper>
      <PageBlockTitle>Remaining Credits</PageBlockTitle>
      <LimitsSection>
        <LimitBlock
          title="Historical Keyword Performance Requests"
          remaining={(data?.keywordPerformanceLimit - data?.keywordPerformanceUsed).toString()}
          loading={isLoading}
        />
        <LimitBlock
          title="Optimize Product Listing Requests"
          remaining={(data?.productOptimizationLimit - data?.productOptimizationUsed).toString()}
          loading={isLoading}
        />
      </LimitsSection>
    </PageBlockWrapper>
  );
};
