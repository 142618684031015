import Analytics, { AnalyticsInstance } from 'analytics';
// @ts-ignore
import googleAnalytics from '@analytics/google-analytics';
// @ts-ignore
import googleTagManager from '@analytics/google-tag-manager';

export enum GoogleAnalyticsEvent {
  USER_SIGNUP = 'user_signup',
  USER_LOGIN = 'user_login',
  KEYWORD_SEARCH = 'keyword_search',
  SAVE_PRODUCT = 'save_product',
  OPTIMIZE_PRODUCT = 'optimize_product',
}

// TODO(!) make singleton?
const analytics = Analytics({
  app: 'tradewinds-ts-server',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-483LX74P4K'],
    }),
    googleTagManager({
      containerId: 'GTM-5RWDZHWG',
    }),
  ],
});

export const trackEvent = (event: GoogleAnalyticsEvent) => {
  console.log('EVENT: ', event);
  analytics.track(event);
};
