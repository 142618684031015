import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import styled from 'styled-components';
import { Typography } from '@mui/joy';
import { UserSavedAmazonListing } from '../../monorepoShared/types';
import { useState } from 'react';
import { OptimizationHistory } from '../OptimizationHistory/OptimizationHistory';

const CellImage = styled.img`
  height: 70%;
  object-fit: contain;
`;

const columns: Array<GridColDef> = [
  {
    field: 'asin',
    headerName: 'ASIN',
    width: 100,
    renderCell: (params) => {
      return <Typography level={'body-xs'}>{params.value}</Typography>;
    },
  },
  {
    headerAlign: 'left',
    field: 'imageUrl',
    headerName: 'Product',
    align: 'left',
    renderCell: (params) => {
      return (
        <>
          <CellImage src={params.value} />
          <Typography level={'body-xs'}>{params.row.title}</Typography>
        </>
      );
    }, // renderCell will render the component
    sortable: false,
    flex: 1,
  },
  {
    field: 'marketplace',
    headerName: 'Marketplace',
    width: 125,
    renderCell: (params) => {
      return <Typography level={'body-xs'}>🇺🇸 Amazon US</Typography>;
    },
  },
  {
    field: 'history',
    headerName: 'History',
    width: 100,
    renderCell: (params) => {
      return <OptimizationHistory asin={params.row.asin} />;
    },
  },
];

export const ExistingListingsTable = ({
  rows,
  loading,
  selectedProduct,
  setProductToOptimizeAndProceed,
}: {
  rows: Array<UserSavedAmazonListing>;
  loading: boolean;
  selectedProduct: UserSavedAmazonListing | undefined;
  setProductToOptimizeAndProceed: (s: UserSavedAmazonListing | undefined) => void;
}) => {
  const handleRowSelectionChange = (newRowSelectionModel: any) => {
    if (newRowSelectionModel.length === 0) {
      // setProductToOptimizeAndProceed(undefined);
    } else {
      const newSelectedId = newRowSelectionModel[newRowSelectionModel.length - 1];
      if (newSelectedId !== selectedProduct?.id) {
        const newSelectedProduct = rows.find((r) => r.id === newSelectedId);
        // setProductToOptimizeAndProceed(newSelectedProduct);
      }
    }
  };

  return (
    <DataGrid
      sx={{
        '& .MuiDataGrid-columnHeaderTitle': {
          whiteSpace: 'normal',
          lineHeight: 'normal',
          fontWeight: 700,
          fontSize: '12px',
        },
        '& .MuiDataGrid-columnHeader': {
          backgroundColor: '#F5F5F5',
        },
        '& .MuiDataGrid-row': {
          cursor: 'pointer',
        },
        '& .MuiDataGrid-cell:focus-within': {
          outline: 'none !important',
        },
      }}
      autoHeight
      columns={columns}
      rows={rows}
      isCellEditable={() => false}
      disableColumnMenu={true}
      loading={loading}
      onRowSelectionModelChange={handleRowSelectionChange}
      onRowClick={(x) => {
        setProductToOptimizeAndProceed(x.row);
      }}
      rowSelectionModel={selectedProduct ? [selectedProduct.id] : []}
    />
  );
};
