import { PageBlockWrapper } from '../../components/Common';
import styled from 'styled-components';
import { Chip, Input, Textarea, Typography } from '@mui/joy';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/joy/Button';
import { CreatePostProcessingIterationReq } from '../../monorepoShared/types';
import axios from 'axios';

// DUPLICATED FROM HARD-CODED PROMPT ITERATOR
export const PRODUCT_PLACEHOLDER = '<PRODUCT_PLACEHOLDER>';
export const SEARCH_PHRASES_PLACEHOLDER = '<SEARCH_PHRASES_PLACEHOLDER>';
export const EXAMPLES_PLACEHOLDER = '<EXAMPLES_PLACEHOLDER>';
export const CONTENT_TO_POST_PROCESS_PLACEHOLDER = '<CONTENT_TO_POST_PROCESS_PLACEHOLDER>';
export const SEVERITY_LANGUAGE_PLACEHOLDER = '<SEVERITY_LANGUAGE_PLACEHOLDER>';
export const CONTENT_TYPE_PLACEHOLDER = '<CONTENT_TYPE_PLACEHOLDER>';

const PromptInputWrapper = styled.div``;

const Flex = styled.div`
  display: flex;
`;

const CopyableChip = ({ str }: { str: string }) => {
  return (
    <Chip
      sx={{
        cursor: 'pointer',
      }}
      onClick={() => {
        navigator.clipboard.writeText(str);
      }}
    >
      {str}
    </Chip>
  );
};

export const PostProcessingIterator = () => {
  // General stuff
  const [promptIterationRunId, setPromptIterationRunId] = React.useState(187);

  // Lengthen
  const [lengthenTitleSystemMessage, setLengthenTitleSystemMessage] = React.useState('' as string);
  const [lengthenTitlePrompt, setLengthenTitlePrompt] = React.useState('' as string);
  const [lengthenDescriptionSystemMessage, setLengthenDescriptionSystemMessage] = React.useState(
    '' as string,
  );
  const [lengthenDescriptionPrompt, setLengthenDescriptionPrompt] = React.useState('' as string);
  const [lengthenBulletPointSystemMessage, setLengthenBulletPointSystemMessage] = React.useState(
    '' as string,
  );
  const [lengthenBulletPointPrompt, setLengthenBulletPointPrompt] = React.useState('' as string);
  // Shorten
  const [shortenTitleSystemMessage, setShortenTitleSystemMsg] = React.useState('' as string);
  const [shortenTitlePrompt, setShortenTitlePrompt] = React.useState('' as string);
  const [shortenDescriptionSystemMessage, setShortenDescriptionSystemMessage] = React.useState(
    '' as string,
  );
  const [shortenDescriptionPrompt, setShortenDescriptionPrompt] = React.useState('' as string);
  const [shortenBulletPointSystemMessage, setShortenBulletPointSystemMessage] = React.useState(
    '' as string,
  );
  const [shortenBulletPointPrompt, setShortenBulletPointPrompt] = React.useState('' as string);

  return (
    <PageBlockWrapper>
      <PromptInputWrapper>
        <Flex>
          <Typography
            sx={{
              lineHeight: '35px',
              marinRight: '10px',
            }}
          >
            Prompt Iteration Id
          </Typography>
          <Input
            value={promptIterationRunId}
            onChange={(e) => {
              // @ts-ignore
              setPromptIterationRunId(e.target.value);
            }}
          />
        </Flex>
        <Typography>Shit you can use in prompts (click to copy)</Typography>
        <>
          <CopyableChip str={PRODUCT_PLACEHOLDER} />
          <CopyableChip str={SEARCH_PHRASES_PLACEHOLDER} />
          <CopyableChip str={EXAMPLES_PLACEHOLDER} />
          <CopyableChip str={CONTENT_TO_POST_PROCESS_PLACEHOLDER} />
          <CopyableChip str={SEVERITY_LANGUAGE_PLACEHOLDER} />
          <CopyableChip str={CONTENT_TYPE_PLACEHOLDER} />
        </>
        <Typography level={'h3'}>Lengthening Prompts</Typography>
        <Typography level={'h4'}>Title</Typography>
        <Typography>System Message</Typography>
        <Textarea
          size={'sm'}
          value={lengthenTitleSystemMessage}
          onChange={(e) => setLengthenTitleSystemMessage(e.target.value)}
          minRows={3}
        />
        <Typography>Prompt</Typography>
        <Textarea
          size={'sm'}
          value={lengthenTitlePrompt}
          onChange={(e) => setLengthenTitlePrompt(e.target.value)}
          minRows={3}
        />
        <Typography level={'h4'}>Description</Typography>
        <Typography>System Message</Typography>
        <Textarea
          size={'sm'}
          value={lengthenDescriptionSystemMessage}
          onChange={(e) => setLengthenDescriptionSystemMessage(e.target.value)}
          minRows={3}
        />
        <Typography>Prompt</Typography>
        <Textarea
          size={'sm'}
          value={lengthenDescriptionPrompt}
          onChange={(e) => setLengthenDescriptionPrompt(e.target.value)}
          minRows={3}
        />
        <Typography level={'h4'}>Bullet Point</Typography>
        <Typography>System Message</Typography>
        <Textarea
          size={'sm'}
          value={lengthenBulletPointSystemMessage}
          onChange={(e) => setLengthenBulletPointSystemMessage(e.target.value)}
          minRows={3}
        />
        <Typography>Prompt</Typography>
        <Textarea
          size={'sm'}
          value={lengthenBulletPointPrompt}
          onChange={(e) => setLengthenBulletPointPrompt(e.target.value)}
          minRows={3}
        />
        <Typography level={'h3'}>Shortening Prompts</Typography>
        <Typography level={'h4'}>Title</Typography>
        <Typography>System Message</Typography>
        <Textarea
          size={'sm'}
          value={shortenTitleSystemMessage}
          onChange={(e) => setShortenTitleSystemMsg(e.target.value)}
          minRows={3}
        />
        <Typography>Prompt</Typography>
        <Textarea
          size={'sm'}
          value={shortenTitlePrompt}
          onChange={(e) => setShortenTitlePrompt(e.target.value)}
          minRows={3}
        />
        <Typography level={'h4'}>Description</Typography>
        <Typography>System Message</Typography>
        <Textarea
          size={'sm'}
          value={shortenDescriptionSystemMessage}
          onChange={(e) => setShortenDescriptionSystemMessage(e.target.value)}
          minRows={3}
        />
        <Typography>Prompt</Typography>
        <Textarea
          size={'sm'}
          value={shortenDescriptionPrompt}
          onChange={(e) => setShortenDescriptionPrompt(e.target.value)}
          minRows={3}
        />
        <Typography level={'h4'}>Bullet Point</Typography>
        <Typography>System Message</Typography>
        <Textarea
          size={'sm'}
          value={shortenBulletPointSystemMessage}
          onChange={(e) => setShortenBulletPointSystemMessage(e.target.value)}
          minRows={3}
        />
        <Typography>Prompt</Typography>
        <Textarea
          size={'sm'}
          value={shortenBulletPointPrompt}
          onChange={(e) => setShortenBulletPointPrompt(e.target.value)}
          minRows={3}
        />
      </PromptInputWrapper>

      <Button
        fullWidth={true}
        onClick={async () => {
          const reqBody: CreatePostProcessingIterationReq = {
            promptIterationRunId,
            lengthenTitleSystemMessage,
            lengthenTitlePrompt,
            lengthenDescriptionSystemMessage,
            lengthenDescriptionPrompt,
            lengthenBulletPointSystemMessage,
            lengthenBulletPointPrompt,
            shortenTitleSystemMessage,
            shortenTitlePrompt,
            shortenDescriptionSystemMessage,
            shortenDescriptionPrompt,
            shortenBulletPointSystemMessage,
            shortenBulletPointPrompt,
          };
          await axios.post('/api/fine-tune/create-post-processing-iteration', reqBody);
        }}
      >
        SUBMIT
      </Button>
    </PageBlockWrapper>
  );
};
