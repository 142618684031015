import styled from 'styled-components';
import { Typography } from '@mui/joy';
import React from 'react';
import { OptimizedListing } from '../../monorepoShared/types';

export const FakeTextArea = styled.div`
  width: 100%;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  border: 1px solid #ceced7;
`;

export const SingleProductListing = ({ product }: { product: OptimizedListing }) => {
  return (
    <>
      <Typography level={'body-xs'} fontWeight={600}>
        Title
      </Typography>
      <FakeTextArea>
        <Typography level={'body-sm'}>{product.title}</Typography>
      </FakeTextArea>
      <Typography level={'body-xs'} fontWeight={600}>
        Description
      </Typography>
      <FakeTextArea>
        <Typography level={'body-sm'}>{product.description}</Typography>
      </FakeTextArea>
      <Typography level={'body-xs'} fontWeight={600}>
        Bullet Points
      </Typography>
      <FakeTextArea>
        {product.bulletPoints.map((bullet) => {
          return <Typography level={'body-sm'}>-{bullet}</Typography>;
        })}
      </FakeTextArea>
    </>
  );
};
