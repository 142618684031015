import styled from 'styled-components';
import React, { Children } from 'react';
import { theme, OPEN_NAV_WIDTH, HEADER_HEIGHT, CLOSED_NAV_WIDTH } from '../styleConsts';
import { NavMenu } from './NavMenu';
import { useLocalStorage } from '@uidotdev/usehooks';

export const SideBarDiv = styled.div<{ expanded: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: ${(props) => (props.expanded ? OPEN_NAV_WIDTH : CLOSED_NAV_WIDTH)};
  background-color: ${theme.lightBackground00};
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.7);
`;

export const AppTitle = styled.div`
  font-size: 16px;
  height: ${HEADER_HEIGHT};
  line-height: ${HEADER_HEIGHT}
  text-align: center;
  border-bottom: 1px solid transparent;
  font-weight: 900;
`;

export const FullWidthLogoImg = styled.img`
  position: relative;
  //width: 140px;
  height: 28px;
  margin-bottom: -20px;
`;

export const CollapsedImg = styled.img`
  height: 28px;
  margin-bottom: -20px;
`;

export const ContentWrapper = styled.div<{ expanded: boolean }>`
  margin-top: 20px;
  margin-left: ${(props) => (props.expanded ? OPEN_NAV_WIDTH : CLOSED_NAV_WIDTH)};
  width: calc(100% - ${(props) => (props.expanded ? OPEN_NAV_WIDTH : CLOSED_NAV_WIDTH)});
  box-sizing: border-box;
  padding: 0 20px 20px;
`;

function FullLayout({ pageTitle, ...props }: any) {
  const [sidebarExpanded, setSidebarExpanded] = useLocalStorage('sidebarExpanded', true);

  return (
    <>
      <SideBarDiv expanded={sidebarExpanded}>
        {sidebarExpanded ? (
          <AppTitle>
            <FullWidthLogoImg src={'/appLogoTextWhite.svg'} />
          </AppTitle>
        ) : (
          <AppTitle>
            <CollapsedImg src={'/appLogoNoTextWhite.png'} />
          </AppTitle>
        )}
        <NavMenu sidebarExpanded={sidebarExpanded} setSidebarExpanded={setSidebarExpanded} />
      </SideBarDiv>
      {/*<Header pageTitle={pageTitle} />*/}
      <ContentWrapper expanded={sidebarExpanded}>{props.children}</ContentWrapper>
    </>
  );
}

export default FullLayout;
