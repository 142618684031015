import { Tooltip, Typography } from '@mui/joy';
import { IterativeGenerationNode } from '../../monorepoShared/types';
import styled from 'styled-components';
import { useState } from 'react';
import Link from '@mui/joy/Link';

const SingleNodeWrapper = styled.div`
  padding: 10px;
  border: 1px solid #ccc;
`;

const StyledPre = styled.pre`
  background-color: #eee;
  padding: 10px;
  overflow: scroll;
`;

const Collapsable = styled.div`
  display: ${(props: { show: boolean }) => (props.show ? 'block' : 'none')};
`;

const SingleNode = ({ node }: { node: IterativeGenerationNode }) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [showSystemPrompt, setShowSystemPrompt] = useState(false);
  const [showInput, setShowInput] = useState(false);

  return (
    <SingleNodeWrapper>
      <Typography level={'body-xs'}>Purpose: {node.purpose}</Typography>
      <Typography level={'body-xs'}>Sub-Purpose: {node.subPurpose || ''}</Typography>
      <Typography level={'body-xs'}>Input: {node.input}</Typography>
      <Typography level={'body-xs'}>
        System Message:{' '}
        <Link
          onClick={() => {
            setShowSystemPrompt(!showSystemPrompt);
          }}
        >
          Click to {showSystemPrompt ? 'Hide' : 'Show'}
        </Link>
        <Collapsable show={showSystemPrompt}>
          <StyledPre>{node.systemMessage}</StyledPre>
        </Collapsable>
      </Typography>
      <Typography level={'body-xs'}>
        Prompt:{' '}
        <Link
          onClick={() => {
            setShowPrompt(!showPrompt);
          }}
        >
          Click to {showPrompt ? 'Hide' : 'Show'}
        </Link>
        <Collapsable show={showPrompt}>
          <StyledPre>{node.prompt}</StyledPre>
        </Collapsable>
      </Typography>
      <Typography level={'body-xs'}>Output: {node.response}</Typography>
    </SingleNodeWrapper>
  );
};

export const PromptChainViewer = ({ chain }: { chain: Array<IterativeGenerationNode> }) => {
  return (
    <>
      {chain.map((node) => {
        return <SingleNode node={node} />;
      })}
    </>
  );
};
