import { PromptIterationRunRow } from '../../monorepoShared/types';
import React, { useState } from 'react';
import { Chip, Link, Typography } from '@mui/joy';
import { SingleProductListing } from './SingleProductListing';
import { PromptChainViewer } from './PromptChainViewer';
import ReactDiffViewer from 'react-diff-viewer';
import styled from 'styled-components';

const Collapsable = styled.div`
  display: ${(props: { show: boolean }) => (props.show ? 'block' : 'none')};
`;

const SingleRow = styled.div`
  display: flex;
`;

const SingleRowContentBlock = styled.div`
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid lightgray;
`;

const GreyBg = styled.div`
  background-color: #f0f0f0;
  padding: 10px;
  margin-bottom: 25px;
`;

const SmallPre = styled.pre`
  font-size: 10px;
`;

export const PromptIterationSingleResult = (row: PromptIterationRunRow) => {
  const [showDiff, setShowDiff] = useState<boolean>(false);
  const [showExampleProducts, setShowExampleProducts] = useState<boolean>(false);
  const [showAttributes, setShowAttributes] = useState<boolean>(false);
  const [showFullPrompt, setShowFullPrompt] = useState<boolean>(false);
  const [showFullTitlePrompt, setShowFullTitlePrompt] = useState<boolean>(false);
  const [showFullDescriptionPrompt, setShowFullDescriptionPrompt] = useState<boolean>(false);
  const [showFullBulletPrompt, setShowFullBulletPrompt] = useState<boolean>(false);

  const titleLenPass = row.objectiveQualityMetrics?.evaluation.titleLength.pass;
  const titleLen = row.objectiveQualityMetrics?.evaluation.titleLength.metadata as number;
  const descriptionLenPass = row.objectiveQualityMetrics?.evaluation.descriptionLength.pass;
  const descriptionLen = row.objectiveQualityMetrics?.evaluation.descriptionLength
    .metadata as number;
  const bulletLenPass = row.objectiveQualityMetrics?.evaluation.bulletPointsLength.pass;
  const bulletLen = row.objectiveQualityMetrics?.evaluation.bulletPointsLength
    .metadata as Array<string>;

  return (
    <>
      {/*Original Content*/}
      <SingleRowContentBlock>
        <Typography level={'body-md'} fontWeight={600}>
          Optimized Content
        </Typography>
        <SingleProductListing product={row.parsedResponse} />
      </SingleRowContentBlock>

      {/*Objective Checks*/}
      <SingleRowContentBlock>
        <Typography level={'body-md'} fontWeight={600}>
          Objective Checks
        </Typography>
        <Chip
          sx={{ padding: '5px 15px', marginRight: '10px' }}
          size={'sm'}
          color={titleLenPass ? 'success' : 'danger'}
          startDecorator={titleLenPass ? '✅' : '❌'}
        >
          Title Length: ({titleLen.toString()})
        </Chip>
        <Chip
          sx={{ padding: '5px 15px', marginRight: '10px' }}
          size={'sm'}
          color={descriptionLenPass ? 'success' : 'danger'}
          startDecorator={descriptionLenPass ? '✅' : '❌'}
        >
          Description Length: ({descriptionLen})
        </Chip>
        <Chip
          sx={{ padding: '5px 15px', marginRight: '10px' }}
          size={'sm'}
          color={bulletLenPass ? 'success' : 'danger'}
          startDecorator={bulletLenPass ? '✅' : '❌'}
        >
          Bullet Lengths: ({bulletLen.join(', ')})
        </Chip>
      </SingleRowContentBlock>

      {/*Search Phrases*/}
      <SingleRowContentBlock>
        <Typography level={'body-md'} fontWeight={600}>
          Search Phrases
        </Typography>
        {row.selectedPhrases.map((phrase) => {
          return (
            <Chip
              sx={{ padding: '5px 15px', marginRight: '10px' }}
              size={'sm'}
              startDecorator={'🔍'}
            >
              {phrase}
            </Chip>
          );
        })}
      </SingleRowContentBlock>

      {/*Full Title Chain*/}
      <SingleRowContentBlock>
        <SingleRow>
          <Typography level={'body-md'} fontWeight={600}>
            Title Prompt Chain
          </Typography>
          <Link
            sx={{
              marginLeft: '10px',
            }}
            onClick={() => {
              setShowFullPrompt(!showFullPrompt);
            }}
          >
            {showFullPrompt ? 'Hide' : 'Show'}
          </Link>
        </SingleRow>

        <Collapsable show={showFullPrompt}>
          <PromptChainViewer chain={row.titleChain!} />
        </Collapsable>
      </SingleRowContentBlock>

      {/*Full Description Chain*/}
      <SingleRowContentBlock>
        <SingleRow>
          <Typography level={'body-md'} fontWeight={600}>
            Description Prompt Chain
          </Typography>
          <Link
            sx={{
              marginLeft: '10px',
            }}
            onClick={() => {
              setShowFullDescriptionPrompt(!showFullDescriptionPrompt);
            }}
          >
            {showFullDescriptionPrompt ? 'Hide' : 'Show'}
          </Link>
        </SingleRow>

        <Collapsable show={showFullDescriptionPrompt}>
          <PromptChainViewer chain={row.descriptionChain!} />
        </Collapsable>
      </SingleRowContentBlock>

      {/*Full Bullet Chain*/}
      <SingleRowContentBlock>
        <SingleRow>
          <Typography level={'body-md'} fontWeight={600}>
            Bullet Points Prompt Chain
          </Typography>
          <Link
            sx={{
              marginLeft: '10px',
            }}
            onClick={() => {
              setShowFullBulletPrompt(!showFullBulletPrompt);
            }}
          >
            {showFullBulletPrompt ? 'Hide' : 'Show'}
          </Link>
        </SingleRow>

        <Collapsable show={showFullBulletPrompt}>
          <PromptChainViewer chain={row.bulletPointsChain!} />
        </Collapsable>
      </SingleRowContentBlock>

      {/*Product Attributes*/}
      <SingleRowContentBlock>
        <SingleRow>
          <Typography level={'body-md'} fontWeight={600}>
            Product Attributes
          </Typography>
          <Link
            sx={{
              marginLeft: '10px',
            }}
            onClick={() => {
              setShowAttributes(!showAttributes);
            }}
          >
            {showAttributes ? 'Hide' : 'Show'}
          </Link>
        </SingleRow>
        <Collapsable show={showAttributes}>
          <SmallPre>{JSON.stringify(row.amazonProduct, null, 2)}</SmallPre>
        </Collapsable>
      </SingleRowContentBlock>

      {/*Syntactic Difference Viewer*/}
      <SingleRowContentBlock>
        <SingleRow>
          <Typography level={'body-md'} fontWeight={600}>
            Syntactic Difference Viewer
          </Typography>
          <Link
            sx={{
              marginLeft: '10px',
            }}
            onClick={() => {
              setShowDiff(!showDiff);
            }}
          >
            {showDiff ? 'Hide' : 'Show'}
          </Link>
        </SingleRow>
        <Collapsable show={showDiff}>
          <Typography>Title</Typography>
          <ReactDiffViewer
            leftTitle={'Original Title'}
            rightTitle={'Optimized Title'}
            oldValue={row.amazonProduct.title}
            newValue={row.parsedResponse.title}
            splitView={true}
            hideLineNumbers={true}
          />
          <Typography>Description</Typography>
          <ReactDiffViewer
            leftTitle={'Original Description'}
            rightTitle={'Optimized Description'}
            oldValue={row.amazonProduct.description}
            newValue={row.parsedResponse.description}
            splitView={true}
            hideLineNumbers={true}
          />
          <Typography>Bullet Points</Typography>
          <ReactDiffViewer
            leftTitle={'Original Bullets'}
            rightTitle={'Optimized Bullets'}
            oldValue={row.amazonProduct.bulletPoints.join('\n')}
            newValue={row.parsedResponse.bulletPoints.join('\n')}
            splitView={true}
            hideLineNumbers={true}
          />
        </Collapsable>
      </SingleRowContentBlock>

      {/*Example products*/}
      <SingleRowContentBlock>
        <SingleRow>
          <Typography level={'body-md'} fontWeight={600}>
            Example Products
          </Typography>
          <Link
            sx={{
              marginLeft: '10px',
            }}
            onClick={() => {
              setShowExampleProducts(!showExampleProducts);
            }}
          >
            {showExampleProducts ? 'Hide' : 'Show'}
          </Link>
        </SingleRow>
        <Collapsable show={showExampleProducts}>
          {row.exampleListings.map((product) => {
            return (
              <GreyBg>
                <Typography level={'body-sm'} fontWeight={600}>
                  {product.productIdentifier}
                </Typography>
                <SingleProductListing product={product} />
              </GreyBg>
            );
          })}
        </Collapsable>
      </SingleRowContentBlock>
    </>
  );
};
