import styled from 'styled-components';
import { Typography } from '@mui/joy';

export const PageBlockWrapper = styled.div`
  padding: 10px 10px;
`;

const PageBlockTitleWrapper = styled(Typography)``;

export const PageBlockTitle = (props: any) => {
  return (
    <PageBlockTitleWrapper level="h3" fontWeight="bold">
      {props.children}
    </PageBlockTitleWrapper>
  );
};
