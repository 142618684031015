import { SingleOptimizedListingLayout } from '../ListingOptimizer/SingleOptimizedListingLayout';
import React, { useEffect, useState } from 'react';
import { SingleChannelOptimizedListing, UserSavedAmazonListing } from '../../monorepoShared/types';
import { Typography } from '@mui/joy';
import { ProductInfoV2 } from '../ListingOptimizer/ProductInfoV2';
import axios from 'axios';
import styled from 'styled-components';
import { mockOptimizationForLoading } from './mockReferenceProduct';

const Spacing = styled.div`
  height: 20px;
`;

export const OptimizedListingBlock = ({
  originalProduct,
  amazonOptimizationId,
}: {
  originalProduct?: UserSavedAmazonListing;
  amazonOptimizationId: string;
}) => {
  const [optimizedListing, setOptimizedListing] = useState<
    SingleChannelOptimizedListing | undefined
  >(mockOptimizationForLoading);

  const fetchSalesChannelOptimizedListing = async (
    amazonOptimizationId: string,
  ): Promise<SingleChannelOptimizedListing> => {
    const response = await axios.get<SingleChannelOptimizedListing>(
      `/api/optimize/single-channel/${amazonOptimizationId}`,
    );
    return response.data;
  };

  useEffect(() => {
    fetchSalesChannelOptimizedListing(amazonOptimizationId).then((optimizedListing) => {
      setOptimizedListing(optimizedListing);
    });
    const interval = setInterval(async () => {
      const optimizedListing = await fetchSalesChannelOptimizedListing(amazonOptimizationId);
      if (optimizedListing.optimizationStatus === 'COMPLETED') {
        clearInterval(interval);
      }
      setOptimizedListing(optimizedListing);
    }, 5000);
  }, [amazonOptimizationId]);

  if (optimizedListing === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Typography level={'h3'}>View Optimized Listing</Typography>
      <Typography
        sx={{
          marginBottom: '10px',
        }}
      >
        The following content has been rewritten using state of the art AI technology to maximize
        your listing's performance for the selected search phrases. As part of the AI's training, it
        has scraped the best performing listings for the selected search phrases in order to infer
        best practices for the specific product category your listing belongs to.
      </Typography>
      <ProductInfoV2
        loading={originalProduct === undefined}
        product={{
          title: originalProduct?.title,
          asin: originalProduct?.asin,
          mainPhotoUrl: originalProduct?.imageUrl,
        }}
      />
      <Spacing />
      <SingleOptimizedListingLayout
        optimizedListing={optimizedListing}
        selectedSearchPhrases={optimizedListing.searchPhrases}
      />
    </>
  );
};
