import * as React from 'react';
import Box from '@mui/material/Box';
import {
  DataGridPro,
  GridColDef,
  GridColumnHeaderParams,
  GridRowSelectionModel,
  GridToolbarContainer,
  GridToolbarExport,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro';
// import { searchPhraseData } from './dempData';
import { Skeleton, Typography } from '@mui/joy';
import styled from 'styled-components';

const columns: GridColDef[] = [
  {
    field: 'searchPhrase',
    headerName: 'Search Phrase',
    flex: 1,
    renderCell: (params) => {
      if (params.value == 'LOADING') {
        return <Skeleton height={20} width={180} />;
      } else {
        return <Typography level={'body-xs'}>{params.value}</Typography>;
      }
    },
  },
  {
    field: 'searchFrequencyScore',
    headerName: 'Search Rank Score',
    type: 'number',
    width: 120,
    disableColumnMenu: true,
    align: 'center',
    renderCell: (params) => {
      if (params.value == 'LOADING') {
        return <Skeleton height={20} width={50} />;
      } else {
        return <Typography level={'body-xs'}>{params.value}</Typography>;
      }
    },
  },
  {
    field: 'relevanceScore',
    headerName: 'Relevance Score',
    type: 'number',
    width: 120,
    disableColumnMenu: true,
    align: 'center',
    renderCell: (params) => {
      if (params.value == 'LOADING') {
        return <Skeleton height={20} width={50} />;
      } else {
        return <Typography level={'body-xs'}>{params.value}</Typography>;
      }
    },
  },
  {
    field: 'combinedScore',
    headerName: 'Combined Score',
    type: 'number',
    width: 120,
    disableColumnMenu: true,
    align: 'center',
    valueGetter: (params: GridValueGetterParams) =>
      ((params.row.searchFrequencyScore * params.row.relevanceScore) / 100).toFixed(1),
    renderCell: (params) => {
      if (params.row.searchPhrase == 'LOADING') {
        return <Skeleton height={20} width={50} />;
      } else {
        return <Typography level={'body-xs'}>{params.value}</Typography>;
      }
    },
  },
];

export const SearchPhraseTable = ({
  searchPhraseData,
  selectedSearchPhrases,
  setSelectedSearchPhrases,
  loading,
  tableHeight = '350px',
}: {
  searchPhraseData: any;
  selectedSearchPhrases: Set<string>;
  setSelectedSearchPhrases: (s: Set<string>) => void;
  loading?: boolean;
  tableHeight?: string;
}) => {
  // TODO(!) This shouldn't be done here -- this is not effecient

  const rows: any[] = [];
  if (loading) {
    // Push a bunch of empty rows
    for (let i = 0; i < 10; i++) {
      rows.push({
        id: i,
        searchPhrase: 'LOADING',
        relevanceScore: 'LOADING',
        searchFrequencyScore: 'LOADING',
      });
    }
  } else {
    for (const [searchPhrase, data] of Object.entries(searchPhraseData.searchPhraseData)) {
      rows.push({
        id: searchPhrase,
        searchPhrase,
        // @ts-ignore
        relevanceScore: (data.relevanceScore * 100).toFixed(1),
        // @ts-ignore
        searchFrequencyScore: data.searchFrequencyScore.toFixed(1),
      });
    }
  }

  return (
    <Box sx={{ width: '100%', marginTop: '10px', height: '350px' }}>
      <DataGridPro
        // autoHeight
        sx={{
          fontSize: '12px',
          '& .MuiDataGrid-columnHeaderTitle': {
            whiteSpace: 'normal',
            lineHeight: 'normal',
            fontSize: '10px',
            fontWeight: 600,
          },
          '& .MuiDataGrid-columnHeader': {
            // Forced to use important since overriding inline styles
            height: 'unset !important',
          },
          '& .MuiDataGrid-columnHeaders': {
            // Forced to use important since overriding inline styles
            // maxHeight: '168px !important',
            // backgroundColor: '#eee',
          },
        }}
        rows={rows}
        columns={columns}
        hideFooterPagination={true}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 200,
            },
          },
          sorting: {
            sortModel: [{ field: 'combinedScore', sort: 'desc' }],
          },
        }}
        // pageSizeOptions={[10]}
        checkboxSelection
        disableRowSelectionOnClick
        showCellVerticalBorder
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setSelectedSearchPhrases(new Set(newRowSelectionModel.map((z) => z.toString())));
        }}
        rowSelectionModel={Array.from(selectedSearchPhrases)}
        rowHeight={30}
      />
    </Box>
  );
};
