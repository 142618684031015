import { Textarea, Typography } from '@mui/joy';
import Button from '@mui/joy/Button';
import React from 'react';
import styled from 'styled-components';
import { numTokensFromString, renderPrompt } from './InternalPromptIterator';

const Columns = styled.div``;

const RawPromptInput = styled.div``;

const PromptPreviewInput = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;

  pre {
    overflow: scroll;
    width: 100%;
    height: 610px;
    border: 1px solid grey;
  }
`;

export const PRODUCT_PLACEHOLDER = '<PRODUCT_PLACEHOLDER>';
export const SEARCH_PHRASE_PLACEHOLDER = '<SEARCH_PHRASES_PLACEHOLDER>';
export const EXAMPLES_PLACEHOLDER = '<EXAMPLES_PLACEHOLDER>';
// export const JSON_TYPE_PLACEHOLDER = `
// Your response should be a valid JSON object with the following structure:
// {
//   "title": string,
// }
//
// Nothing other than valid JSON should be returned.
// `;

export const JSON_TYPE_PLACEHOLDER = {
  title: `
Your response should be a valid JSON object with the following structure:
{
  "title": string,
}

Nothing other than valid JSON should be returned.
`,
  description: `
Your response should be a valid JSON object with the following structure:
{
  "description": string,
}
  
Nothing other than valid JSON should be returned.
`,
  bulletPoints: `
Your response should be a valid JSON object with the following structure:
{
  "bulletPoints": string[],
}
  
Nothing other than valid JSON should be returned.
`,
};

export const PromptsInput = ({
  type,
  systemMessage,
  setSystemMessage,
  rawPromptInput,
  setRawPromptInput,
}: {
  type: 'title' | 'description' | 'bulletPoints';
  systemMessage: string;
  setSystemMessage: (s: string) => void;
  rawPromptInput: string;
  setRawPromptInput: (s: string) => void;
}) => {
  const [showFinalPrompt, setShowFinalPrompt] = React.useState(false);

  const append = (s: string) => {
    setRawPromptInput(`${rawPromptInput}${s}`);
  };

  return (
    <Columns>
      <RawPromptInput>
        <Typography level={'body-md'} fontWeight={500}>
          Enter System Message
        </Typography>
        <Textarea
          size={'sm'}
          value={systemMessage}
          onChange={(e) => setSystemMessage(e.target.value)}
          minRows={8}
        />
        <Typography level={'body-md'} fontWeight={500}>
          Enter Raw Prompt
        </Typography>
        <>
          <Button
            size="sm"
            variant={'outlined'}
            onClick={() => {
              append(PRODUCT_PLACEHOLDER);
            }}
          >
            Add Product
          </Button>
          <Button
            size="sm"
            variant={'outlined'}
            sx={{
              marginLeft: '5px;',
            }}
            onClick={() => {
              append(SEARCH_PHRASE_PLACEHOLDER);
            }}
          >
            Add Search Terms
          </Button>
          <Button
            size="sm"
            variant={'outlined'}
            sx={{
              marginLeft: '5px;',
            }}
            onClick={() => {
              append(EXAMPLES_PLACEHOLDER);
            }}
          >
            Add Examples
          </Button>
          <Button
            size="sm"
            variant={'outlined'}
            sx={{
              marginLeft: '5px;',
            }}
            onClick={() => {
              append(JSON_TYPE_PLACEHOLDER[type]);
            }}
          >
            Add JSON Type Message
          </Button>
        </>
        <Textarea
          size={'sm'}
          minRows={8}
          sx={{
            marginTop: '5px',
          }}
          value={rawPromptInput}
          onChange={(e) => {
            setRawPromptInput(e.target.value);
          }}
        />
      </RawPromptInput>
      <PromptPreviewInput>
        <Typography level={'body-md'} fontWeight={500}>
          Final Prompt Preview
        </Typography>
        <Typography level={'body-sm'}>
          Token count: {numTokensFromString(renderPrompt(rawPromptInput))}
        </Typography>
        <Button
          variant={'outlined'}
          fullWidth={false}
          size={'sm'}
          onClick={() => {
            setShowFinalPrompt(!showFinalPrompt);
          }}
        >
          {showFinalPrompt ? 'Hide' : 'Show'}
        </Button>
        {showFinalPrompt && <pre>{renderPrompt(rawPromptInput)}</pre>}
      </PromptPreviewInput>
    </Columns>
  );
};
