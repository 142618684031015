export const OPEN_NAV_WIDTH = '180px';
export const CLOSED_NAV_WIDTH = '60px';
export const HEADER_HEIGHT = '60px;';

export const theme = {
  lighterBackground: 'lime',
  lightBackground00: '#02536d',
  lightBackground01: '#024257',
  fontColor: '#FFFDF5',
};
