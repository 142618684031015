import styled from 'styled-components';
import { theme } from '../styleConsts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faReceipt,
  faWandMagicSparkles,
  faArrowRightFromBracket,
  faTable,
  faAnglesLeft,
  faAnglesRight,
  faUserPlus,
  faRightToBracket,
  faEnvelopeOpen,
  faCreditCard,
  faMagnifyingGlassChart,
} from '@fortawesome/free-solid-svg-icons';
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Card, CardActions, CardContent, Skeleton, Tooltip, Typography } from '@mui/joy';
import { useAuth } from './auth/authHooks';
import Button from '@mui/joy/Button';
import { GetUsageResponse } from '../monorepoShared/types';

const NavMenuGroup = styled.div`
  padding: 10px;
  margin-bottom: 25px;
  margin-top: 20px;
`;

const NavMenuGroupTitleDiv = styled.div`
  margin-bottom: 15px;
`;

const NavMenuGroupTitle = styled.span`
  color: #fffdfe;
  font-size: 10px;
  padding: 10px;
  font-weight: bold;
`;

const NavMenuLinkDiv = styled.div<any>`
  background-color: ${(props) => (props.active ? theme.lightBackground01 : 'transparent')};
  display: flex;
  padding: 10px 12px;
  margin-bottom: 5px;
  border-radius: 5px;
  cursor: pointer;
`;

const NavMenuLinkIcon = styled.div<any>`
  font-size: 12px;
  width: 14px;
  color: ${(props) => (props.active ? theme.fontColor : theme.fontColor)};
  margin-right: 10px;
`;

const NavMenuLinkText = styled.span<any>`
  color: ${(props) => (props.active ? theme.fontColor : theme.fontColor)};
  font-size: 12px;
  font-weight: 500;
`;

const ToggleSidebarSizeButton = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #9b9b9b;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
`;

function NavMenuLink(props: any) {
  if (!props.route && !props.onClick) {
    throw new Error('NavMenuLink must have either a route or an onClick');
  }

  const navigate = useNavigate();
  const location = useLocation();
  let onClickOrNoop = props.onClick ? props.onClick : () => navigate(props.route);
  if (props.disabled) {
    onClickOrNoop = () => {};
  }
  // Set active if the location matches the route, including additional path segments
  const active = !props.onClick && location.pathname.startsWith(props.route);

  return (
    <NavMenuLinkDiv active={active} onClick={onClickOrNoop}>
      <NavMenuLinkIcon active={active}>
        <FontAwesomeIcon icon={props.icon} />
      </NavMenuLinkIcon>
      <NavMenuLinkText active={active}>{props.text}</NavMenuLinkText>
    </NavMenuLinkDiv>
  );
}

export function NavMenu({
  sidebarExpanded,
  setSidebarExpanded,
}: {
  sidebarExpanded: boolean;
  setSidebarExpanded: (b: boolean) => void;
}) {
  const [usage, setUsage] = React.useState<GetUsageResponse | undefined>(undefined);
  const navigate = useNavigate();
  const { isAuthenticated, getUsage } = useAuth();

  const getAndSetUsage = () => {
    if (isAuthenticated) {
      getUsage().then((resp) => {
        setUsage(resp);
      });
    }
  };

  useEffect(() => {
    // Call setUsage every 5 seconds
    getAndSetUsage();
    const interval = setInterval(() => {
      getAndSetUsage();
    }, 15000);
    return () => clearInterval(interval);
  }, [isAuthenticated]);

  return (
    <>
      <NavMenuGroup>
        <NavMenuGroupTitleDiv>
          <NavMenuGroupTitle>{sidebarExpanded ? 'MENU' : ''}</NavMenuGroupTitle>
        </NavMenuGroupTitleDiv>
        <Tooltip
          title={'Login to access the optimizer'}
          arrow
          placement={'right'}
          sx={{
            display: `${!isAuthenticated ? 'block' : 'none'}`,
          }}
        >
          <div>
            <NavMenuLink
              text={sidebarExpanded ? 'Listing Optimizer' : ''}
              icon={faWandMagicSparkles}
              route="/optimizer"
              disabled={!isAuthenticated}
            />
          </div>
        </Tooltip>
        <NavMenuLink
          text={sidebarExpanded ? 'Keyword Research' : ''}
          icon={faMagnifyingGlassChart}
          route="/keywords"
        />
        {/*{!isAuthenticated ? (*/}
        {/*  <Tooltip title={'Create an account to view history'} arrow placement={'right'}>*/}
        {/*    <div>*/}
        {/*      <NavMenuLink text={sidebarExpanded ? 'History' : ''} icon={faTable} route="/history" onClick={() => {}} />*/}
        {/*    </div>*/}
        {/*  </Tooltip>*/}
        {/*) : (*/}
        {/*  <NavMenuLink text={sidebarExpanded ? 'History' : ''} icon={faTable} route="/history" />*/}
        {/*)}*/}
      </NavMenuGroup>
      <NavMenuGroup>
        <NavMenuGroupTitleDiv>
          <NavMenuGroupTitle>{sidebarExpanded ? 'ACCOUNT' : ''}</NavMenuGroupTitle>
        </NavMenuGroupTitleDiv>
        {isAuthenticated ? (
          <>
            <NavMenuLink text={sidebarExpanded ? 'Usage' : ''} icon={faReceipt} route="/usage" />
            <NavMenuLink
              text={sidebarExpanded ? 'Get Credits' : ''}
              icon={faCreditCard}
              route="/credits"
            />
            <NavMenuLink
              text={sidebarExpanded ? 'Logout' : ''}
              icon={faArrowRightFromBracket}
              onClick={() => {
                localStorage.removeItem('authToken');
                localStorage.removeItem('user.id');
                navigate('/login');
              }}
            />
          </>
        ) : (
          <NavMenuLink
            text={sidebarExpanded ? 'Login' : ''}
            icon={faRightToBracket}
            onClick={() => {
              localStorage.removeItem('authToken');
              localStorage.removeItem('user.id');
              navigate('/login');
            }}
          />
        )}
      </NavMenuGroup>
      <ToggleSidebarSizeButton
        onClick={() => {
          setSidebarExpanded(!sidebarExpanded);
        }}
      >
        <FontAwesomeIcon icon={sidebarExpanded ? faAnglesLeft : faAnglesRight} />
      </ToggleSidebarSizeButton>
      <NavMenuGroup>
        <NavMenuGroupTitleDiv>
          <NavMenuGroupTitle>{sidebarExpanded ? 'ADMIN' : ''}</NavMenuGroupTitle>
        </NavMenuGroupTitleDiv>
        <Tooltip title={'Click to email us at hello@gotradewinds.ai'} arrow placement={'right'}>
          <div>
            <NavMenuLink
              text={sidebarExpanded ? 'Feedback' : ''}
              icon={faEnvelopeOpen}
              route="/"
              onClick={() => {
                window.location.href = 'mailto:hello@gotradewinds.ai?subject=TradeWinds AI';
              }}
            />
          </div>
        </Tooltip>
      </NavMenuGroup>
      <Box
        sx={{
          position: 'absolute',
          bottom: 50,
          left: 0,
          width: '100%',
          textAlign: 'center',
          lineHeight: '50px',
          padding: '10px',
          boxSizing: 'border-box',
          display: `${sidebarExpanded && isAuthenticated ? 'block' : 'none'}`,
        }}
      >
        <Card
          sx={{
            backgroundColor: `${theme.lightBackground01}`,
            border: 'none',
            // borderRadius: '0px',
            padding: '10px',
          }}
        >
          <CardContent>
            <Typography
              level={'body-sm'}
              fontWeight={600}
              sx={{
                color: `${theme.fontColor}`,
              }}
            >
              Remaining Credits
            </Typography>
            <Typography
              sx={{
                color: 'white',
                fontSize: '32px',
                marginBottom: '-10px',
              }}
            >
              <Skeleton variant={'inline'} loading={usage === undefined}>
                {usage ? usage.productOptimization.limit - usage.productOptimization.used : 'XXXX'}
              </Skeleton>
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              size={'sm'}
              sx={{
                fontSize: '12px',
              }}
              onClick={
                isAuthenticated
                  ? () => {
                      navigate('/credits');
                    }
                  : () => {
                      navigate('/login');
                    }
              }
            >
              Get More Credits
            </Button>
          </CardActions>
        </Card>
      </Box>
    </>
  );
}
