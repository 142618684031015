import { Typography } from '@mui/joy';
import Button from '@mui/joy/Button';
import styled from 'styled-components';
import { useState } from 'react';

const Wrapper = styled.div`
  width: 500px;
  margin: 0 auto;
  top: 50%;
  position: relative;
  transform: translateY(-50%);
`;

const Page = styled.div`
  height: 100vh;
  width: 100vw;
`;

// Bridge length = 1.25
// Min disstance = 10
// Max distance = 40
// Always end on the other side of the bridge

const possibleDistances = [
  11.25, // BK
  13.75, // BK
  16.25, // BK
  18.75, // BK
  21.25, // BK
  23.75, // BK
  26.25, // BK
  28.75, // BK
  31.25, // BK
  33.75, // BK
  36.25, // BK
  38.75, // BK
];

const DistanceWrapper = styled.div`
  position: relative;
  background-color: lightblue;
  height: 350px;
`;

export const WBMMileageSelector = () => {
  const [distance, setDistance] = useState<string>('~???~');

  const pickDistance = () => {
    // Pick a random distance
    const randomIndex = Math.floor(Math.random() * possibleDistances.length);
    const dist = possibleDistances[randomIndex];
    const lapCount = dist / 1.25;
    const minutes = Math.floor(dist * 4.5);
    // Create a new Date object for today's date
    const now = new Date();

    // Set the hours to 8 PM and minutes to 0
    now.setHours(20);
    now.setMinutes(0);
    now.setSeconds(0);

    // Add the calculated minutes to the time
    now.setMinutes(now.getMinutes() + minutes);

    const formattedTime = now.toLocaleTimeString('en-US');

    const st = `${dist} miles\n Laps: ${lapCount}\n Time to announce: ${formattedTime}`;
    setDistance(st);
  };
  return (
    <Page>
      <Wrapper>
        <DistanceWrapper>
          <Typography
            level={'h1'}
            textAlign={'center'}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            {distance}
          </Typography>
        </DistanceWrapper>
        <Button
          fullWidth
          onClick={function () {
            pickDistance();
          }}
        >
          Generate Distance
        </Button>
      </Wrapper>
    </Page>
  );
};
