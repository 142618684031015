import React, { useState } from 'react';
import { PageBlockTitle, PageBlockWrapper } from '../../components/Common';
import styled from 'styled-components';
import { Input, Option, Select, Typography } from '@mui/joy';
import Button from '@mui/joy/Button';
import { mockExampleProducts, mockInputProduct, mockSearchPhrases } from './mockData';
import axios from 'axios';
import { PromptIteratorTable } from './PromptIteratorTable';
import { PromptsInput } from './PromptsInput';

const EXPECTED_OUTPUT_TOKENS = 500;

const InputLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const HyperParamsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ParameterWrapper = styled.div`
  display: flex;
  margin-right: 10px;
`;

// NOTE -- THIS IS DUPLICATED IN FINE-TUNE-CREATOR
export const renderPrompt = (rawPrompt: string) => {
  const product = mockInputProduct;
  const searchPhrases = mockSearchPhrases;
  const exampleListings = mockExampleProducts;

  const productBlock = JSON.stringify(
    {
      title: product.title,
      description: product.description,
      bulletPoints: product.bulletPoints,
      attributes: product.attributes,
    },
    null,
    4,
  );
  const searchPhraseBlock = searchPhrases.join('\n');
  const examplesBlock = exampleListings
    .map((l) => {
      return JSON.stringify(
        {
          title: l.title,
          description: l.description,
          bulletPoints: l.bulletPoints,
        },
        null,
        4,
      );
    })
    .join(',\n');

  return rawPrompt
    .replace(/<PRODUCT_PLACEHOLDER>/g, `${productBlock}`)
    .replace(/<SEARCH_PHRASES_PLACEHOLDER>/g, `${searchPhraseBlock}`)
    .replace(/<EXAMPLES_PLACEHOLDER>/g, `${examplesBlock}`);
};

export const numTokensFromString = (message: string) => {
  return Math.ceil(message.split('').length * 0.36787944117144232159552377016146);
};

export const InternalPromptIterator = () => {
  const [systemMessage, setSystemMessage] = useState(
    'You are an professional e-commerce consultant. Your goal is to improve the copy on existing Amazon Product Display Page to turn it into a fully optimized copy. You are well know for writing product copy that adheres strictly to the requirements presented to you. ',
  );
  const [rawPromptInput, setRawPromptInput] = useState('');
  const [temp, setTemp] = useState(0.4);
  const [maxLength, setMaxLength] = useState(400);
  const [topP, setTopP] = useState(1);
  const [frequencyPenalty, setFrequencyPenalty] = useState(0);
  const [presencePenalty, setPresencePenalty] = useState(0);
  const [prevRunForAsins, setPrevRunForAsins] = useState<number | undefined>(undefined);
  const [numAsins, setNumAsins] = useState(3);
  const [notes, setNotes] = useState('');
  const [optimizationType, setOptimizationType] = useState<
    'title' | 'description' | 'bulletPoints'
  >('description');

  const previewPrompt = renderPrompt(rawPromptInput);
  const promptTokens = numTokensFromString(previewPrompt);
  const inputTokenPrice = 0.03 / 1000;
  const outputTokenPrice = 0.06 / 1000;
  const estimatedPricePerASIN =
    promptTokens * inputTokenPrice + EXPECTED_OUTPUT_TOKENS * outputTokenPrice;
  const estimatedTotalPrice = estimatedPricePerASIN * numAsins;

  return (
    <PageBlockWrapper>
      <PageBlockTitle>Prompt Iteration Run</PageBlockTitle>
      <br />
      <PromptIteratorTable />
      <Typography
        level={'h4'}
        sx={{
          marginTop: '10px',
          marginBottom: '10px',
        }}
      >
        Run New Iteration
      </Typography>
      <Select
        value={optimizationType}
        onChange={(e, v) => {
          setOptimizationType(v as 'title' | 'description' | 'bulletPoints');
        }}
      >
        <Option value={'title'}>Title</Option>
        <Option value={'description'}>Description</Option>
        <Option value={'bulletPoints'}>Bullet Points</Option>
      </Select>
      <InputLayoutWrapper>
        <Typography level={'body-lg'} fontWeight={700} sx={{ marginTop: '10px' }}>
          {optimizationType.toUpperCase()} Generation
        </Typography>
        <PromptsInput
          type={optimizationType}
          systemMessage={systemMessage}
          setSystemMessage={setSystemMessage}
          rawPromptInput={rawPromptInput}
          setRawPromptInput={setRawPromptInput}
        />
        <Typography level={'h4'}>Set Hyper-parameters</Typography>
        <HyperParamsWrapper>
          <ParameterWrapper>
            <Typography
              sx={{
                lineHeight: '35px',
              }}
            >
              Temperature:
            </Typography>
            <Input
              value={temp}
              sx={{
                width: '60px',
                marginLeft: '5px',
              }}
              onChange={(e) => {
                // @ts-ignore
                setTemp(e.target.value);
              }}
            ></Input>
          </ParameterWrapper>
          <ParameterWrapper>
            <Typography
              sx={{
                lineHeight: '35px',
              }}
            >
              maxLength (ignored):
            </Typography>
            <Input
              value={maxLength}
              sx={{
                width: '80px',
                marginLeft: '5px',
              }}
              onChange={(e) => {
                // @ts-ignore
                setMaxLength(e.target.value);
              }}
            ></Input>
          </ParameterWrapper>
          <ParameterWrapper>
            <Typography
              sx={{
                lineHeight: '35px',
              }}
            >
              topP:
            </Typography>
            <Input
              value={topP}
              sx={{
                width: '60px',
                marginLeft: '5px',
              }}
              onChange={(e) => {
                // @ts-ignore
                setTopP(e.target.value);
              }}
            ></Input>
          </ParameterWrapper>
          <ParameterWrapper>
            <Typography
              sx={{
                lineHeight: '35px',
              }}
            >
              frequencyPenalty:
            </Typography>
            <Input
              value={frequencyPenalty}
              sx={{
                width: '60px',
                marginLeft: '5px',
              }}
              onChange={(e) => {
                // @ts-ignore
                setFrequencyPenalty(e.target.value);
              }}
            ></Input>
          </ParameterWrapper>
          <ParameterWrapper>
            <Typography
              sx={{
                lineHeight: '35px',
              }}
            >
              presencePenalty:
            </Typography>
            <Input
              value={presencePenalty}
              sx={{
                width: '60px',
                marginLeft: '5px',
              }}
              onChange={(e) => {
                // @ts-ignore
                setPresencePenalty(e.target.value);
              }}
            ></Input>
          </ParameterWrapper>
          <ParameterWrapper>
            <Typography
              sx={{
                lineHeight: '35px',
              }}
            >
              Use prev runs ASINS:
            </Typography>
            <Input
              value={prevRunForAsins}
              type={'number'}
              onChange={(e) => {
                setPrevRunForAsins(parseInt(e.target.value));
              }}
              sx={{
                width: '80px',
                marginLeft: '5px',
              }}
            ></Input>
          </ParameterWrapper>
          <ParameterWrapper>
            <Typography
              sx={{
                lineHeight: '35px',
              }}
            >
              Set number of (random) ASINS:
            </Typography>
            <Input
              value={numAsins}
              type={'number'}
              onChange={(e) => {
                setNumAsins(parseInt(e.target.value));
              }}
              sx={{
                width: '80px',
                marginLeft: '5px',
              }}
            ></Input>
          </ParameterWrapper>
        </HyperParamsWrapper>
        <Typography level={'h4'}>Add some notes to identify this iteration</Typography>
        <Input
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder={'Add some notes'}
        />
        <Button
          size={'lg'}
          sx={{
            marginTop: '10px;',
          }}
          onClick={async () => {
            const fieldSpecificPromptKey = `${optimizationType}Prompt`;
            const fieldSpecificSystemMessageKey = `${optimizationType}SystemMessage`;
            await axios.post('/api/fine-tune/create-iteration', {
              prompt: rawPromptInput,
              [fieldSpecificPromptKey]: rawPromptInput,
              [fieldSpecificSystemMessageKey]: systemMessage,
              systemMessage,
              notes,
              temperature: temp,
              maximumLength: maxLength,
              topP,
              frequencyPenalty,
              presencePenalty,
              numAsins,
              prevRunForAsins: prevRunForAsins,
            });
          }}
        >
          Run Experiment: ${estimatedPricePerASIN.toFixed(2)}/ASIN, $
          {estimatedTotalPrice.toFixed(2)} total
        </Button>
      </InputLayoutWrapper>
    </PageBlockWrapper>
  );
};
