import { createContext, useEffect, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { configure } from 'axios-hooks';
import { Link, useNavigate } from 'react-router-dom';
import { GetUsageResponse } from '../../monorepoShared/types';

const loginRequest = async ({ loginData }: { loginData: { email: string; password: string } }) => {
  const loginResponse = await axios.post('/api/auth/login', loginData);
  const resp = {
    token: loginResponse.data.access_token,
    user: loginResponse.data.user,
  };
  localStorage.setItem('authToken', resp.token);
  // This ID seems.. bad? Unclear tbhj
  localStorage.setItem('user.email', resp.user.email);
  localStorage.setItem('user.id', resp.user.id);
  return resp;
};

const registerRequest = async ({
  registerData,
}: {
  registerData: { email: string; password: string };
}) => {
  const registerResponse = await axios.post('/api/auth/register', registerData);
  const resp = {
    token: registerResponse.data.access_token,
    user: registerResponse.data.id,
  };
  localStorage.setItem('authToken', resp.token);
  localStorage.setItem('user.id', resp.user);
  return resp;
};

export const AuthContext = createContext({});

export function AuthProvider(props: any) {
  // What the fuck is this ref?
  const accessTokenRef = useRef<string>();
  const [axiosConfigured, setAxiosConfigured] = useState(false);
  const [usage, setUsage] = useState<GetUsageResponse | undefined>(undefined);

  const loginQuery = useMutation(['login'], loginRequest, {
    onSuccess: (data) => {
      accessTokenRef.current = data.token;
    },
  });

  const registerQuery = useMutation(['register'], registerRequest, {
    onSuccess: (data) => {
      accessTokenRef.current = data.token;
    },
  });

  const login = async (loginData: { email: string; password: string }) => {
    await loginQuery.mutateAsync({ loginData });
  };

  const register = async (registerData: { email: string; password: string }) => {
    await registerQuery.mutateAsync({ registerData });
  };

  const logout = () => {
    // here you should send a request to your backend to invalidate the refresh-token
    // and then set the accessTokenRef to undefined.
    accessTokenRef.current = undefined;
    localStorage.removeItem('authToken');
    localStorage.removeItem('user');
    loginQuery.reset();
  };

  const getUsage = async (): Promise<GetUsageResponse | undefined> => {
    if (!axiosConfigured) {
      return undefined;
    }
    const resp = await axios.get<GetUsageResponse>('/api/users/myUsage');
    return resp.data;
  };

  useEffect(() => {
    // add authorization token to each request
    axios.interceptors.request.use((config) => {
      // @ts-ignore
      config.headers.authorization = `Bearer ${localStorage.getItem('authToken')}`;
      config.withCredentials = true;
      return config;
    });

    // axios.interceptors.response.use(
    //   (response) => response,
    //   async (error) => {
    //     return Promise.reject(error);
    //   },
    // );

    // configure axios-hooks to use this instance of axios
    configure({ axios });
    setAxiosConfigured(true);
  }, []);

  const authToken = localStorage.getItem('authToken');
  const user = {
    email: localStorage.getItem('user.email'),
    id: localStorage.getItem('user.id'),
  };
  const isAuthenticated = authToken && user;

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        logout,
        register,
        axiosConfigured,
        getUsage,
      }}
      {...props}
    ></AuthContext.Provider>
  );
}
