import React from 'react';
import { Formik, Field, Form } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { useError } from '../../components/ErrorProvider';
import {
  FakeHeader,
  FakeTitle,
  FieldError,
  LoginButton,
  LoginForm,
  LoginInput,
  LoginPageWrapper,
} from './commonAuth';
import { useAuth } from '../../components/auth/authHooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSailboat } from '@fortawesome/free-solid-svg-icons';

export function Login() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const { showError } = useError();

  return (
    <LoginPageWrapper>
      {/*TODO make this the same as the actual header*/}
      <FakeHeader>
        <FakeTitle
          onClick={() => {
            navigate('/keywords');
          }}
        >
          <img src={'/appLogoTextColor.svg'} />
        </FakeTitle>
      </FakeHeader>
      <Formik
        validateOnChange={true}
        initialValues={{
          email: '',
          password: '',
        }}
        validate={(values) => {
          const errors: any = {};
          if (!values.email) {
            errors.email = 'Email required';
          }
          if (!values.password) {
            errors.password = 'Password required';
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }: any) => {
          try {
            await login(values);
            navigate('/optimizer');
          } catch (error: any) {
            if (error.response.status === 401) {
              showError('Invalid email or password');
            } else {
              showError('An unknown error occurred');
            }
          }
        }}
      >
        {({ handleSubmit, isSubmitting, isValid, errors, touched, values }: any) => (
          <LoginForm onSubmit={handleSubmit}>
            <h1>Log in to TradeWinds</h1>
            <Field
              name="email"
              type="email"
              placeholder="Email address"
              as={LoginInput}
              error={errors.email && touched.email}
            />
            <Field
              name="password"
              type="password"
              placeholder="Password"
              as={LoginInput}
              error={errors.password && touched.password}
            />
            <LoginButton
              type="submit"
              disabled={!isValid || isSubmitting || (!values.password && !values.email)}
            >
              {isSubmitting ? 'Loading...' : 'Sign In'}
            </LoginButton>
            <p>
              Don't have an account? <Link to={'/register'}>Register</Link>
            </p>
          </LoginForm>
        )}
      </Formik>
    </LoginPageWrapper>
  );
}

export default Login;
