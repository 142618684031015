import Box from '@mui/material/Box';
import Button from '@mui/joy/Button';
import { Divider, Drawer, List, ListItem, ListItemButton, Typography } from '@mui/joy';
import { useEffect, useState } from 'react';
import { HistoryList } from './HistoryList';
import { GetOptimizationsForAsinResponse, OptimizationMetadata } from '../../monorepoShared/types';
import axios from 'axios';

export const OptimizationHistory = ({ asin }: { asin: string }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState<Array<OptimizationMetadata>>([]);

  const fetchOptimizationHistory = async () => {
    setLoading(true);
    const resp = await axios.get<GetOptimizationsForAsinResponse>(
      `/api/optimize/getOptimizationsForAsin/${asin}`,
    );
    setHistory(resp.data.optimizations);
    setLoading(false);
  };

  const toggleDrawer = (inOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    // disable passing the event to the parent
    event.preventDefault();
    event.stopPropagation();
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(inOpen);
  };

  useEffect(() => {
    fetchOptimizationHistory();
  }, [asin]);

  return (
    <Box sx={{ display: 'flex' }}>
      <Button
        variant="outlined"
        color="neutral"
        onClick={toggleDrawer(true)}
        size={'sm'}
        sx={{
          fontSize: '12px',
        }}
      >
        Show
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)} size={'lg'} anchor={'right'}>
        <Box role="presentation" padding={'20px'}>
          <Typography level={'h3'} marginBottom={'10px'}>
            Optimized Listing History
          </Typography>
          {loading || history.length > 0 ? (
            <HistoryList optimizationMetadata={history} loading={loading} />
          ) : (
            <Typography level={'body-md'}>
              Optimized listings will show up here once you run your first optimization for this
              product.
            </Typography>
          )}
        </Box>
      </Drawer>
    </Box>
  );
};
