import Snackbar from '@mui/joy/Snackbar';
import PlaylistAddCheckCircleRoundedIcon from '@mui/icons-material/PlaylistAddCheckCircleRounded';
import ErrorOutline from '@mui/icons-material/ErrorOutline';

import { useNotification } from './NotificationProvider';

export const Notifications = () => {
  const { notification, showNotification, isError } = useNotification();

  if (!notification) {
    return <></>;
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      autoHideDuration={4000}
      open={Boolean(notification)}
      variant={isError ? 'solid' : 'soft'}
      color={isError ? 'danger' : 'primary'}
      startDecorator={<ErrorOutline />}
      onClose={(event: any, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        showNotification(undefined);
      }}
    >
      {notification}
    </Snackbar>
  );
};
