import { Tooltip, Typography } from '@mui/joy';
import { ProductInfoV2 } from '../ListingOptimizer/ProductInfoV2';
import { Chart } from '../ListingOptimizer/Chart';
import { SearchPhraseTable } from '../ListingOptimizer/SearchPhraseTable';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { mockData } from './mockData';
import Button from '@mui/joy/Button';
import { UserSavedAmazonListing } from '../../monorepoShared/types';
import { KeywordResearchResponse } from '../ListingOptimizer/ListingOptimizer';
import axios from 'axios';
import { LongLoader } from '../../components/LongLoader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useNotification } from '../../components/NotificationProvider';
import { InfoOutlined, InfoRounded, InfoSharp, InfoTwoTone } from '@mui/icons-material';
import { useAuth } from '../../components/auth/authHooks';
import { GoogleAnalyticsEvent, trackEvent } from '../../googleAnalytics/analytics';
// import { useQueryParam, ArrayParam } from 'use-query-params';

const ChartWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  padding: 20px;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  box-sizing: border-box;
`;

const ChartSizeController = styled.div``;

const LoadingSpacer = styled.div`
  height: 30px;
`;

// TODO -- I need to figure out a new entrypoint for this for organic traffic.
export const SelectKeywordBlock = ({
  asinOrSearchPhrase,
  selectedProduct,
  startOptimization,
  forceResetStep,
  showTitle = true,
  errorNavigation = '/optimizer',
  showOptimizationButton = true,
  hideProductInfo = false,
}: {
  asinOrSearchPhrase: {
    type: 'asin' | 'searchPhrase';
    value: string;
  };
  selectedProduct?: UserSavedAmazonListing;
  startOptimization?: (searchPhrases: Array<string>) => void;
  forceResetStep: () => void;
  showTitle?: boolean;
  errorNavigation?: string;
  showOptimizationButton?: boolean;
  hideProductInfo?: boolean;
}) => {
  const { showNotification } = useNotification();
  const [searchParams, setSearchParams] = useSearchParams();
  const [keywordData, setKeywordData] = useState<KeywordResearchResponse | undefined>(undefined);
  const [loadingSearchData, setLoadingSearchData] = useState<boolean>(true);
  const [loadingOptimizationId, setLoadingOptimizationId] = useState(false);
  const loadingSelectedProduct = selectedProduct === undefined;
  const navigate = useNavigate();
  const { getUsage, isAuthenticated } = useAuth();

  const selectedSearchPhrases: Set<string> = new Set<string>(searchParams.getAll('searchPhrases'));
  const setSelectedSearchPhrases = (searchPhrases: Set<string>) => {
    if (searchPhrases.size <= 0) {
      return;
    }
    setSearchParams({ searchPhrases: Array.from(searchPhrases) });
  };

  const fetchKeywordData = async () => {
    try {
      setLoadingSearchData(true);
      trackEvent(GoogleAnalyticsEvent.KEYWORD_SEARCH);
      const body =
        asinOrSearchPhrase.type === 'asin'
          ? { asin: asinOrSearchPhrase.value }
          : { searchPhrase: asinOrSearchPhrase.value };

      const response = await axios.post<KeywordResearchResponse>(
        `/api/search-phrases/getSearchPhraseData`,
        body,
      );
      setKeywordData(response.data);
      const sorted = Object.entries(response.data.searchPhraseData)
        .sort(([searchPhraseA, dataA], [searchPhraseB, dataB]) => {
          return (
            dataB.relevanceScore * dataB.searchFrequencyScore -
            dataA.searchFrequencyScore * dataA.relevanceScore
          );
        })
        .map((tuple) => tuple[0]);
      if (selectedSearchPhrases.size === 0) {
        setSelectedSearchPhrases(new Set<string>(sorted.slice(0, 5)));
      }
      setLoadingSearchData(false);
    } catch (e) {
      showNotification('Error fetching product data', true);
      navigate(errorNavigation);
      forceResetStep();
    }
  };

  useEffect(() => {
    fetchKeywordData();
  }, []);

  return (
    <>
      {showTitle && (
        <>
          <Typography level={'h3'}>Select Keywords to Optimize Product Content</Typography>
        </>
      )}
      {!hideProductInfo && (
        <ProductInfoV2
          product={{
            title: selectedProduct?.title,
            asin: selectedProduct?.asin,
            mainPhotoUrl: selectedProduct?.imageUrl,
          }}
          loading={loadingSelectedProduct}
        />
      )}
      <ChartWrapper className="step1">
        <Typography
          fontWeight={500}
          sx={{
            marginBottom: '10px',
          }}
        >
          Relative Performance Over Time
          <Tooltip
            title={
              'Relative search performance is a measure of how many Amazon users search for specific phrases relative to the highest performing search phrase in the category. For more in depth search metrics, please contact us about our Enterprise Plan.'
            }
            placement={'right'}
            sx={{
              maxWidth: '400px',
            }}
          >
            <InfoOutlined
              sx={{
                fontSize: '14px',
                marginLeft: '5px',
                marginBottom: '-2px',
                cursor: 'pointer',
              }}
            />
          </Tooltip>
        </Typography>
        <ChartSizeController>
          {loadingSearchData ? (
            <>
              <LoadingSpacer />
              <LongLoader loadingMessages={['Analyzing Amazon data']} />
            </>
          ) : (
            <Chart resp={keywordData!} selectedSearchPhrases={selectedSearchPhrases} />
          )}
        </ChartSizeController>
      </ChartWrapper>
      <SearchPhraseTable
        searchPhraseData={keywordData}
        selectedSearchPhrases={selectedSearchPhrases}
        setSelectedSearchPhrases={setSelectedSearchPhrases}
        loading={loadingSearchData}
      />
      {showOptimizationButton && asinOrSearchPhrase.type === 'asin' ? (
        <Button
          fullWidth={true}
          sx={{
            marginTop: '10px',
            position: 'sticky',
            bottom: 0,
          }}
          disabled={loadingOptimizationId || loadingSearchData}
          loading={loadingOptimizationId}
          onClick={async () => {
            if (!isAuthenticated) {
              navigate('/register');
              showNotification('Please create an account to optimize listings.');
              return;
            }
            const credits = await getUsage();
            const remainingCredits =
              credits.productOptimization.limit - credits.productOptimization.used;
            if (remainingCredits <= 0) {
              showNotification(
                'You have used all of your optimization credits. Please purchase more to continue.',
              );
              navigate('/credits');
            } else {
              setLoadingOptimizationId(true);
              startOptimization!(Array.from(selectedSearchPhrases));
            }
          }}
        >
          Optimize Listing For Selected Phrases
        </Button>
      ) : (
        <></>
      )}
    </>
  );
};
