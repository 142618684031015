import { PageBlockWrapper } from '../../components/Common';
import Stepper from '@mui/joy/Stepper';
import Step from '@mui/joy/Step';
import StepIndicator from '@mui/joy/StepIndicator';
import React, { useEffect, useState } from 'react';
import { ChooseProductBlock } from './ChooseProductBlock';
import { SelectKeywordBlock } from './SelectKeywordBlock';
import { OptimizedListingBlock } from './OptimizedListingBlock';
import { UserSavedAmazonListing } from '../../monorepoShared/types';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../components/auth/authHooks';
import { useNotification } from '../../components/NotificationProvider';
import { GoogleAnalyticsEvent, trackEvent } from '../../googleAnalytics/analytics';

export const ListingOptimizerV2Wrapper = () => {
  // TODO -- add query params that accept the selected search phrases in order to make
  // a better expiereince for going from keywod research to optimization
  const { asin, optimizationId } = useParams();
  const navigate = useNavigate();
  const { isAuthenticated, axiosConfigured } = useAuth();
  const { showNotification } = useNotification();

  // DSA
  let initialActiveStep = 0;
  if (asin && !optimizationId) {
    initialActiveStep = 1;
  }
  if (asin && optimizationId) {
    initialActiveStep = 2;
  }

  const [activeStep, setActiveStep] = useState(initialActiveStep);
  const [productToOptimize, setProductToOptimize] = useState<UserSavedAmazonListing | undefined>(
    undefined,
  );
  const [amazonOptimizationId, setAmazonOptimizationId] = useState<string | undefined>(undefined);

  if (optimizationId && !asin) {
    // TODO -- perhaps throw here instead?
    throw new Error('Optimization ID provided without ASIN');
  }

  const setProductToOptimizeAndProceed = (product: UserSavedAmazonListing | undefined) => {
    if (!product) {
      return;
    }
    setProductToOptimize(product);
    navigate(`/optimizer/${product.asin}`);
    setActiveStep(1);
  };

  const startOptimization = async (searchPhrasesToOptimize: Array<string>) => {
    trackEvent(GoogleAnalyticsEvent.OPTIMIZE_PRODUCT);
    const response = await axios.post<{ singleChannelOptimizationIdByChannel: { AMAZON: string } }>(
      `/api/optimize`,
      {
        searchPhrases: searchPhrasesToOptimize,
        product: {
          asin: productToOptimize!.asin,
          title: productToOptimize!.title,
        },
        channels: ['AMAZON'],
      },
    );
    navigate(
      `/optimizer/${productToOptimize!.asin}/opt/${
        response.data.singleChannelOptimizationIdByChannel.AMAZON
      }`,
    );
    setAmazonOptimizationId(response.data.singleChannelOptimizationIdByChannel.AMAZON);
    setActiveStep(2);
  };

  const fetchSelectedListingByAsin = async (asin: string) => {
    try {
      const response = await axios.get<UserSavedAmazonListing>(
        `/api/products/getSavedListings/${asin}`,
      );
      setProductToOptimize(response.data);
    } catch (error) {
      showNotification('Could not find product', true);
    }
  };

  const forceResetStep = () => {
    setActiveStep(0);
  };

  useEffect(() => {
    if (axiosConfigured && asin) {
      fetchSelectedListingByAsin(asin);
    }
    if (axiosConfigured && asin && optimizationId) {
      setAmazonOptimizationId(optimizationId);
      setActiveStep(2);
    }
    if (asin && !optimizationId) {
      setActiveStep(1);
    }
    if (!asin && !optimizationId) {
      setActiveStep(0);
    }
  }, [axiosConfigured, asin, optimizationId]);

  if (!isAuthenticated) {
    navigate('/keywords');
    return <> </>;
  }

  return (
    <PageBlockWrapper>
      <Stepper sx={{ width: '100%', marginBottom: '20px' }}>
        <Step
          onClick={() => {
            navigate(`/optimizer`);
          }}
          sx={{ cursor: 'pointer' }}
          indicator={
            <StepIndicator
              variant={activeStep === 0 ? 'solid' : 'soft'}
              color={activeStep === 0 ? 'primary' : 'neutral'}
            >
              1
            </StepIndicator>
          }
        >
          Choose Product
        </Step>
        <Step
          onClick={() => {
            if (activeStep === 2 && productToOptimize) {
              navigate(`/optimizer/${productToOptimize.asin}`);
            }
          }}
          sx={{ cursor: 'pointer' }}
          indicator={
            <StepIndicator
              variant={activeStep === 1 ? 'solid' : 'soft'}
              color={activeStep === 1 ? 'primary' : 'neutral'}
            >
              2
            </StepIndicator>
          }
        >
          Select Keywords
        </Step>
        <Step
          indicator={
            <StepIndicator
              variant={activeStep === 2 ? 'solid' : 'soft'}
              color={activeStep === 2 ? 'primary' : 'neutral'}
            >
              3
            </StepIndicator>
          }
        >
          Optimize Content
        </Step>
      </Stepper>

      {activeStep === 0 && (
        <ChooseProductBlock setProductToOptimizeAndProceed={setProductToOptimizeAndProceed!} />
      )}
      {activeStep === 1 && (
        <SelectKeywordBlock
          asinOrSearchPhrase={{
            type: 'asin',
            value: asin!,
          }}
          selectedProduct={productToOptimize!}
          startOptimization={startOptimization}
          forceResetStep={forceResetStep}
          showOptimizationButton={true}
        />
      )}
      {activeStep === 2 && amazonOptimizationId && (
        <OptimizedListingBlock
          amazonOptimizationId={amazonOptimizationId}
          originalProduct={productToOptimize!}
        />
      )}
    </PageBlockWrapper>
  );
};
