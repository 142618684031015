import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { CircularProgress, Typography } from '@mui/joy';
import { useAuth } from '../../components/auth/authHooks';
import Button from '@mui/joy/Button';

const getColorForPercentage = (pct: number): string => {
  // Ensure the percentage is between 0 and 100
  pct = Math.min(Math.max(pct, 0), 100);

  if (pct < 60) {
    return '#ffcccb';
  } else if (pct < 75) {
    // light oragne
    return '#ffcc99';
  } else if (pct < 90) {
    // light yellow
    return '#ffffaa';
  } else {
    return '#90ee90';
  }
};

const getPercentageTypography = (num: number): JSX.Element => {
  const color = getColorForPercentage(num);

  return (
    <Typography
      level={'body-xs'}
      sx={{
        backgroundColor: color,
        width: '100%',
        padding: '10px',
      }}
    >
      {num.toFixed(0)}%
    </Typography>
  );
};

const columns: Array<GridColDef> = [
  {
    field: 'id',
    headerName: 'id',
    width: 30,
  },
  {
    field: 'asins',
    headerName: 'asins',
    renderCell: (params) => {
      return params.row.asins.length;
    },
    width: 30,
  },
  {
    field: 'notes',
    headerName: 'notes',
    flex: 3,
  },
  {
    field: 'numCompleted',
    headerName: '%',
    width: 70,
    renderCell: (params) => {
      const numCompleted = params.row.numCompleted;
      const numTotal = params.row.asins.length;
      const percent = (numCompleted / numTotal) * 100;
      return percent.toFixed(1) + '%';
    },
  },
  {
    field: 'status',
    headerName: 'status',
    width: 50,
    renderCell: (params) => {
      if (params.row.status === 'REQUESTED') {
        return <Typography level={'body-xs'}>🔜</Typography>;
      }

      if (params.row.status === 'IN_PROGRESS') {
        return (
          // Loading spinner
          <Typography level={'body-xs'}>🔄</Typography>
        );
      }
      return (
        // Loading spinner
        <Typography level={'body-xs'}>✅</Typography>
      );
    },
  },
  {
    field: 'titleLegnthPassRate',
    headerName: 'Title Pass',
    width: 70,
    renderCell: (params) => {
      const num = (params.row?.objectiveResultsAggregation?.titleLength || 0) * 100;
      return getPercentageTypography(num);
    },
  },
  {
    field: 'titleLengthAvgScore',
    headerName: 'Title Score',
    width: 70,
    renderCell: (params) => {
      const num = (params.row?.objectiveResultsAggregation?.titleLengthAvgScore || 0) * 100;
      return getPercentageTypography(num);
    },
  },
  {
    field: 'descLenfthPassRate',
    headerName: 'Desc Pass',
    width: 70,
    renderCell: (params) => {
      const num = (params.row?.objectiveResultsAggregation?.descriptionLength || 0) * 100;
      return getPercentageTypography(num);
    },
  },
  {
    field: 'descLengthAvgScore',
    headerName: 'Desc Score',
    width: 70,
    renderCell: (params) => {
      const num = (params.row?.objectiveResultsAggregation?.descriptionLengthAvgScore || 0) * 100;
      return getPercentageTypography(num);
    },
  },
  {
    field: 'bulletLen',
    headerName: 'Bullet Pass',
    width: 70,
    renderCell: (params) => {
      const num = (params.row?.objectiveResultsAggregation?.bulletPointsLength || 0) * 100;
      return getPercentageTypography(num);
    },
  },
  {
    field: 'nulletLengthAvgScore',
    headerName: 'Bullet Score',
    width: 70,
    renderCell: (params) => {
      const num = (params.row?.objectiveResultsAggregation?.bulletPointLengthAvgScore || 0) * 100;
      return getPercentageTypography(num);
    },
  },
  {
    field: 'startsWithBrand',
    headerName: 'Brand Pass',
    width: 70,
    renderCell: (params) => {
      const num = (params?.row?.objectiveResultsAggregation?.startsWithBrand || 0) * 100;
      return getPercentageTypography(num);
    },
  },
  // {
  //   field: 'copyPrompt',
  //   headerName: 'copy prompt',
  //   width: 130,
  //   renderCell: (params) => (
  //     <Button
  //       size={'sm'}
  //       variant={'outlined'}
  //       onClick={async () => {
  //         const copy = params.row.prompt;
  //         await navigator.clipboard.writeText(copy);
  //         console.log('Hello');
  //       }}
  //     >
  //       Copy
  //     </Button>
  //   ),
  // },
  {
    field: 'viewResults',
    headerName: 'Results',
    width: 60,
    renderCell: (params) => (
      <Button
        size={'sm'}
        variant={'plain'}
        onClick={async () => {
          window.location.href = `/prompt/${params.row.id}`;
        }}
      >
        Go
      </Button>
    ),
  },
  // {
  //   field: 'downloadResults',
  //   headerName: 'results',
  //   width: 110,
  //   renderCell: (params) => (
  //     <Button
  //       size={'sm'}
  //       variant={'outlined'}
  //       onClick={async () => {
  //         const runResp = await axios.get(`/api/fine-tune/singleRun/${params.row.id}`);
  //         const data = runResp.data.results.rows as Array<any>;
  //         const betterData = data.map((row) => {
  //           row['failedObjectiveSteps'] =
  //             row?.objectiveQualityMetrics?.failedChecks || 'NOT_CHECKED';
  //           row['initialTitle'] = row?.amazonProduct?.title;
  //           row['generatedTitle'] = row?.parsedResponse?.title || 'ERROR';
  //           row['initialDescription'] = row?.amazonProduct?.description;
  //           row['generatedDescription'] = row?.parsedResponse?.description || 'ERROR';
  //           row['initialBulletPoints'] = row?.amazonProduct?.bulletPoints;
  //           row['generatedBulletPoints'] = row?.parsedResponse?.bulletPoints || 'ERROR';
  //           row['exampleListings'] = row?.exampleListings || 'ERROR';
  //           delete row['rawResponse'];
  //           delete row['parsedResponse'];
  //           return row;
  //         });
  //
  //         const csv = json2csv(betterData, { expandNestedObjects: false });
  //         const blob = new Blob([csv], { type: 'text/csv' });
  //         const url = window.URL.createObjectURL(blob);
  //         const a = document.createElement('a');
  //         a.setAttribute('hidden', '');
  //         a.setAttribute('href', url); // Set the URL as the href attribute of the anchor
  //         a.setAttribute('download', `prompt_iteration_${params.row.id}.csv`); // Set the download attribute with a filename
  //         document.body.appendChild(a); // Append the anchor to the document
  //         a.click(); // Programmatically click the anchor to start download
  //         document.body.removeChild(a); // Optionally remove the anchor after clicking
  //         window.URL.revokeObjectURL(url); // Free up the blob URL
  //       }}
  //     >
  //       Download
  //     </Button>
  //   ),
  // },
];

export const PromptIteratorTable = () => {
  const [data, setData] = useState<Array<any> | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const { axiosConfigured } = useAuth();

  const fetchData = async () => {
    const resp = await axios.get('/api/fine-tune/history');
    setData(resp.data);
    setLoading(false);
  };

  useEffect(() => {
    const i = setInterval(async () => {
      await fetchData();
    }, 2000);
    return () => clearInterval(i);
  }, []);

  if (loading) {
    return <Typography>Loading Table Data...</Typography>;
  }

  if (data) {
    return (
      <DataGrid
        columns={columns}
        rows={data}
        sx={{ overflowX: 'scroll', fontSize: '10px' }}
        pageSizeOptions={[5, 10, 20]}
        initialState={{ pagination: { paginationModel: { pageSize: 10 } } }}
        rowHeight={25}
        columnHeaderHeight={35}
      />
    );
  }

  return <Typography>Something went wrong</Typography>;
};
