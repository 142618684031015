import styled from 'styled-components';
import { CircularProgress } from '@mui/joy';
import { useEffect, useState } from 'react';

const LongLoaderWrapper = styled.div`
  margin: auto;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TextWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
`;

const LoadingMessage = styled.span`
  font-size: 14px;
  color: #444;
`;

export const LongLoader = ({ loadingMessages }: { loadingMessages: Array<string> }) => {
  const [currMessageIndex, setCurrentMessage] = useState(0);

  // Every 5 seconds, change the loading message
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessage((currMessageIndex + 1) % loadingMessages.length);
    }, 3000);
    return () => clearInterval(interval);
  });

  return (
    <LongLoaderWrapper>
      <SpinnerWrapper>
        <CircularProgress size="lg" />
      </SpinnerWrapper>
      <TextWrapper>
        <LoadingMessage>{loadingMessages[currMessageIndex]}...</LoadingMessage>
      </TextWrapper>
    </LongLoaderWrapper>
  );
};
