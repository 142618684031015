import styled from 'styled-components';
import { Avatar, Chip, Skeleton, Typography } from '@mui/joy';
import { useState } from 'react';

const ProductInfoV2Wrapper = styled.div`
  display: flex;
  height: 120px;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;

  // TODO -- this should be somewhere else
  margin-top: 10px;
`;

const ProductInfoV2Image = styled.div`
  height: 100%;
  aspect-ratio: 1;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
`;

const ProductInfoV2Data = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 10px;
`;

const ProductLinks = styled.div``;

// TODO (swap names)
export const ProductInfoV2 = ({
  product,
  loading,
}: {
  product: { mainPhotoUrl?: string; title?: string; asin?: string };
  loading: boolean;
}) => {
  const mockListing = {
    title:
      "Anon Men's M4 Toric Goggles with Spare Lens and MFI Mask, Navy / Perceive Variable Blue",
    asin: 'B08W2KK4XZ',
    mainPhotoUrl:
      'https://m.media-amazon.com/images/I/61vSnQBG7EL.__AC_SX300_SY300_QL70_FMwebp_.jpg',
  };

  return (
    <ProductInfoV2Wrapper>
      <ProductInfoV2Image>
        <Skeleton variant="overlay" height={90} width={90} loading={loading}>
          <img src={loading ? mockListing.mainPhotoUrl : product.mainPhotoUrl} alt="todo" />
        </Skeleton>
      </ProductInfoV2Image>
      <ProductInfoV2Data>
        <Typography level="body-sm">
          <Skeleton variant={'inline'} loading={loading}>
            {loading ? mockListing.title : product.title}
          </Skeleton>
        </Typography>
        <ProductLinks>
          {!loading && (
            <Chip
              variant="outlined"
              color="neutral"
              size="md"
              style={{
                zIndex: 0,
              }}
              startDecorator={
                <Avatar
                  size="lg"
                  src={'/amazonLogo.png'}
                  style={{
                    zIndex: 0,
                  }}
                />
              }
              onClick={() => window.open(`https://www.amazon.com/dp/${product.asin}/`, '_blank')}
            >
              {product.asin}
            </Chip>
          )}
        </ProductLinks>
      </ProductInfoV2Data>
    </ProductInfoV2Wrapper>
  );
};
