import { Checkbox, Link, Skeleton, Typography } from '@mui/joy';
import styled from 'styled-components';
import React from 'react';
import { Scope } from 'eslint';
import { ReferenceProductListing } from '../../monorepoShared/types';

const OptimizationInfoBlockWrapper = styled.div`
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 5px;
  padding: 15px;
  box-sizing: border-box;
  //position: sticky;
  top: 10px;
`;

const mockRational =
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse vitae accumsan tortor, in bibendum justo. Maecenas sed ligula et ipsum iaculis suscipit. Nunc libero risus, volutpat ut ullamcorper ac, tempor ut metus. Vestibulum elementum hendrerit nisi, ac euismod velit porta at. Donec in arcu at neque viverra consequat sit amet nec augue. Donec pretium blandit tortor, sed fermentum velit finibus a. Duis sodales lectus ante, quis viverra turpis ultrices facilisis. Fusce molestie lobortis felis, vel volutpat quam accumsan ut. Aliquam eleifend magna non velit accumsan mollis. Cras aliquet bibendum euismod. ';

export const OptimizationInfoBlock = ({
  prioritizedKeywords,
  rationale,
  toHighlight,
  shouldHighlight,
  setShouldHighlight,
  referenceProductListings,
  loading,
}: {
  prioritizedKeywords: Array<string>;
  rationale: string;
  toHighlight: Record<string, string>;
  shouldHighlight: boolean;
  setShouldHighlight: (b: boolean) => void;
  referenceProductListings?: Array<ReferenceProductListing>;
  loading: boolean;
}) => {
  return (
    <OptimizationInfoBlockWrapper>
      <Typography level="title-md" fontWeight={500}>
        Optimization Rationale
      </Typography>
      <Typography level="body-sm" marginBottom={'10px'}>
        <Skeleton loading={loading}>{rationale}</Skeleton>
      </Typography>
      {referenceProductListings && referenceProductListings.length > 0 && (
        <>
          <Typography level="title-md" fontWeight={500}>
            Reference Product Listings
          </Typography>
          {referenceProductListings.map((listing, index) => (
            <Skeleton variant={'inline'} loading={loading}>
              <div
                key={index}
                style={{
                  marginBottom: '10px',
                }}
              >
                <Link href={listing.productUrl} rel="noopener" target="_blank" fontSize={14}>
                  {listing.title.slice(0, 80)}
                  {listing.title.length > 80 && '...'}
                </Link>
              </div>
            </Skeleton>
          ))}
        </>
      )}
      <Typography level="title-md" fontWeight={500}>
        Prioritized Keywords
      </Typography>
      <Typography
        level="body-sm"
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        {prioritizedKeywords.map((keyword, index) => (
          <span
            key={index}
            style={{
              backgroundColor: toHighlight[keyword] || 'red',
              padding: '2px 3px',
              borderRadius: '5px',
              marginRight: '5px',
              marginBottom: '5px',
            }}
          >
            <Skeleton variant="inline" loading={loading}>
              {keyword}
            </Skeleton>
          </span>
        ))}
      </Typography>
      <Checkbox
        checked={shouldHighlight}
        onChange={(e) => setShouldHighlight(e.target.checked)}
        color="primary"
        size="sm"
        variant="outlined"
        label={'Highlight phrases in text'}
        sx={{ marginTop: '10px' }}
      />
    </OptimizationInfoBlockWrapper>
  );
};
