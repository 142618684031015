import { Box, Button, Card, CardActions, CardContent, Chip, Skeleton, Typography } from '@mui/joy';
import { OptimizationMetadata, OptimizationStatus } from '../../monorepoShared/types';
import { useNavigate } from 'react-router-dom';

const mockHistory: OptimizationMetadata = {
  id: 1,
  asin: 'B08J5QJX9Y',
  optimizationStatus: OptimizationStatus.COMPLETED,
  title:
    'X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X X',
  searchPhrases: [
    'dog',
    'cat',
    'fish',
    'reptile',
    'bird',
    'fish',
    'reptile',
    'bird',
    'fish',
    'reptile',
  ],
  createdAt: '2021-10-10',
};

const mockHistoryList = [mockHistory, mockHistory, mockHistory];

const HistoryCard = ({ info, loading }: { info: OptimizationMetadata; loading: boolean }) => {
  const navigate = useNavigate();

  return (
    <Card variant="outlined" sx={{ marginBottom: '10px' }}>
      <CardContent>
        <Typography level="body-md">
          <Skeleton loading={loading}>{info.title}</Skeleton>
        </Typography>
        <Typography level="body-sm">
          Search Phrases:{' '}
          <Skeleton variant={'inline'} loading={loading}>
            {info.searchPhrases.map((phrase) => (
              <Chip
                key={phrase}
                sx={{
                  marginRight: '5px',
                  marginBottom: '5px',
                }}
              >
                {phrase}
              </Chip>
            ))}
          </Skeleton>
        </Typography>
        <Typography level="body-sm">
          Created at:{' '}
          <Skeleton variant={'inline'} loading={loading}>
            {info.createdAt}
          </Skeleton>
        </Typography>
      </CardContent>
      <CardActions buttonFlex="0 1 120px">
        {/*<IconButton variant="soft" color="neutral" sx={{ mr: 'auto' }} size={'sm'}>*/}
        {/*  <FavoriteBorder />*/}
        {/*</IconButton>*/}
        {/*<Button variant="outlined" color="danger">*/}
        {/*  Delete*/}
        {/*</Button>*/}
        <Button
          variant="outlined"
          color="primary"
          size={'sm'}
          sx={{
            fontSize: '12px',
          }}
          onClick={() => {
            navigate(`/optimizer/${info.asin}/opt/${info.id}`);
          }}
        >
          View
        </Button>
      </CardActions>
    </Card>
  );
};

export const HistoryList = ({
  optimizationMetadata,
  loading,
}: {
  optimizationMetadata?: Array<OptimizationMetadata>;
  loading: boolean;
}) => {
  let optimizationMetadataOrMock: Array<OptimizationMetadata> = mockHistoryList;
  if (optimizationMetadata) {
    optimizationMetadataOrMock = optimizationMetadata;
  }
  return (
    <Box>
      {optimizationMetadataOrMock.map((metadata, idx) => (
        <HistoryCard key={idx} info={metadata} loading={loading} />
      ))}
    </Box>
  );
};
