import { Link, Typography } from '@mui/joy';
import { FlexGrow, getAsinFromUrl, SearchBarWrapper } from '../ListingOptimizer/ListingOptimizer';
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import { ExistingListingsTable } from './ExistingListingsTable';
import { useEffect, useState } from 'react';
import {
  GetUserSavedAmazonListingResponse,
  UserSavedAmazonListing,
} from '../../monorepoShared/types';
import axios from 'axios';
import { useAuth } from '../../components/auth/authHooks';
import styled from 'styled-components';
import { GoogleAnalyticsEvent, trackEvent } from '../../googleAnalytics/analytics';

const Spacer = styled.div`
  height: 18px;
`;

export const ChooseProductBlock = ({
  setProductToOptimizeAndProceed,
}: {
  setProductToOptimizeAndProceed: (x: UserSavedAmazonListing | undefined) => void;
}) => {
  const [data, setData] = useState<Array<UserSavedAmazonListing>>([]); // data is the array of objects
  const [input, setInput] = useState('');
  const [inlineError, setInlineError] = useState<string | undefined>();
  const { axiosConfigured } = useAuth();
  const [inputLoading, setInputLoading] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [selectedProduct, setSelectedProduct] = useState<UserSavedAmazonListing | undefined>(
    undefined,
  );

  const getSavedListings = async () => {
    const response = await axios.get<GetUserSavedAmazonListingResponse>(
      '/api/products/getSavedListings',
    );
    setData(response.data.savedProducts);
  };

  useEffect(() => {
    if (!axiosConfigured) {
      return;
    }
    getSavedListings();
    setTableLoading(false);
  }, [axiosConfigured]);

  const addSavedAsin = async () => {
    setInlineError(undefined);
    setInputLoading(true);
    trackEvent(GoogleAnalyticsEvent.SAVE_PRODUCT);
    let asin = getAsinFromUrl(input);
    if (!asin) {
      asin = input;
    }
    if (asin.length !== 10) {
      setInlineError(
        'Invalid ASIN. Should be 10 characters long. Maybe try a product listing URL?',
      );
      setInputLoading(false);
      return;
    } else {
      const response = await axios.post<GetUserSavedAmazonListingResponse>(
        '/api/products/uploadASIN',
        {
          asin,
        },
      );
      setData(response.data.savedProducts);
      setInputLoading(false);
      setProductToOptimizeAndProceed(response.data.savedProducts.filter((p) => p.asin === asin)[0]);
    }
  };

  return (
    <>
      <Typography level={'h3'}>Start by Adding a Product Below</Typography>
      <Typography
        sx={{
          marginBottom: '20px',
        }}
      >
        You can drop any ASIN or US Amazon product page URL in the field below and hit search to add
        the product. To automatically sync all of the listing you manage on Amazon reach out to us
        about our <Link href={'/credits'}>Enterprise plan.</Link>
      </Typography>
      <SearchBarWrapper>
        <FlexGrow>
          <Input
            color="neutral"
            size="md"
            variant="outlined"
            placeholder={'Enter a product ASIN or URL'}
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
            }}
            onKeyDown={async (e) => {
              {
                if (e.key === 'Enter') {
                  await addSavedAsin();
                }
              }
            }}
          />
        </FlexGrow>
        <Button
          color="primary"
          size="md"
          variant="outlined"
          sx={{ 'margin-left': '5px' }}
          onClick={async () => {
            await addSavedAsin();
          }}
          loading={inputLoading}
        >
          Search
        </Button>
        {/*<Button color="neutral" size="md" variant="soft" sx={{ 'margin-left': '5px' }}>*/}
        {/*  Random ASIN*/}
        {/*</Button>*/}
      </SearchBarWrapper>
      {inlineError ? (
        <Typography level={'body-xs'} color={'danger'}>
          {inlineError}
        </Typography>
      ) : (
        <Spacer />
      )}
      <Typography level={'h3'} marginBottom={'5px'}>
        Your Products
      </Typography>
      <ExistingListingsTable
        rows={data}
        loading={tableLoading}
        selectedProduct={selectedProduct}
        setProductToOptimizeAndProceed={setProductToOptimizeAndProceed}
      />
      <Typography>
        Select a product from the table above to see its performance over time and optimize its
        listing.
      </Typography>
    </>
  );
};
