import { Typography } from '@mui/joy';
import { SelectKeywordBlock } from '../ListingOptimizerV2/SelectKeywordBlock';
import { CreateOptimizationResponse, UserSavedAmazonListing } from '../../monorepoShared/types';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import Input from '@mui/joy/Input';
import styled from 'styled-components';
import Button from '@mui/joy/Button';
import { useNotification } from '../../components/NotificationProvider';
import { getAsinFromUrl, getAsinOrSearchPhrase } from '../ListingOptimizer/ListingOptimizer';
import { PageBlockWrapper } from '../../components/Common';
import { GoogleAnalyticsEvent, trackEvent } from '../../googleAnalytics/analytics';
import { useAuth } from '../../components/auth/authHooks';

const InputWrapper = styled.div`
  width: 100%;
`;

const Flex = styled.div`
  display: flex;
`;

export const KeywordResearch = () => {
  const [savedProduct, setSavedProduct] = useState<UserSavedAmazonListing | undefined>(undefined);
  const navigate = useNavigate();
  const [asin, setAsin] = useState<string | undefined>();
  const [searchPhrase, setSearchPhrase] = useState<string | undefined>();
  const { showNotification } = useNotification();
  const [errorText, setErrorText] = useState<string | undefined>(undefined);
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined);
  const { isAuthenticated, getUsage } = useAuth();

  const fetchSavedProductByAsin = async (asin: string): Promise<UserSavedAmazonListing> => {
    const resp = await axios.get<UserSavedAmazonListing>(`/api/products/getProductByAsin/${asin}`);
    return resp.data;
  };

  const optimize = async (searchPhrases: Array<string>) => {
    if (!isAuthenticated) {
      navigate('/register');
      return;
    }
    const usage = await getUsage();
    const hasOptimizationsRemaining =
      usage.productOptimization.limit - usage.productOptimization.used > 0;
    if (!hasOptimizationsRemaining) {
      showNotification(
        'You have used all of your optimization credits. Please purchase more to continue.',
      );
      navigate('/credits');
    }

    const response = await axios.post<CreateOptimizationResponse>('/api/optimize', {
      product: {
        asin: asin,
      },
      searchPhrases,
      channels: ['AMAZON'],
    });

    navigate(`/optimizer/${asin}/opt/${response.data.singleChannelOptimizationIdByChannel.AMAZON}`);
  };

  const search = async () => {
    if (!searchInput) {
      setErrorText('Please enter an ASIN/Amazon URL or search phrase');
      return;
    }
    navigate(`/keywords`);
    setSearchPhrase(undefined);
    setAsin(undefined);
    setSavedProduct(undefined);
    const { type, value } = getAsinOrSearchPhrase(searchInput);
    if (type === 'asin') {
      setAsin(value);
      const savedProduct = await fetchSavedProductByAsin(value);
      setSavedProduct(savedProduct);
    } else {
      setSearchPhrase(value);
    }
  };

  return (
    <PageBlockWrapper>
      <Typography level={'h3'}>Research Relevant Keywords For Product</Typography>
      <Typography style={{ marginBottom: '20px' }} display={asin ? 'none' : 'block'}>
        We process 10s of millions of real Amazon search phrases and 100s of millions of products to
        identify the highest volume, most relevant search phrases for your product. All of our data
        comes directly from Amazon. To start, enter an ASIN or link to an Amazon listing in the
        search bar below.
      </Typography>
      <InputWrapper>
        <Flex>
          <Input
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
            style={{ marginBottom: '10px' }}
            size={'lg'}
            fullWidth={true}
            placeholder={'Enter an ASIN, Amazon product URL, or any search phrase here'}
          />
          <Button
            sx={{
              marginLeft: '10px',
              height: '45px',
              width: '150px',
            }}
            onClick={search}
          >
            Search
          </Button>
        </Flex>
        <Typography
          color={'danger'}
          sx={{
            marginTop: '-10px',
          }}
        >
          {errorText ?? ' '}
        </Typography>
      </InputWrapper>
      {(asin || searchPhrase) && (
        <>
          <SelectKeywordBlock
            key={asin ?? searchPhrase} // Trick -- this forces the re-render
            showTitle={false}
            asinOrSearchPhrase={{
              type: asin ? 'asin' : 'searchPhrase',
              value: asin! ?? searchPhrase!,
            }}
            startOptimization={optimize}
            forceResetStep={() => {}}
            selectedProduct={savedProduct}
            errorNavigation={'/keywords'}
            showOptimizationButton={true}
            hideProductInfo={!asin}
          />
          <br />
        </>
      )}
    </PageBlockWrapper>
  );
};
